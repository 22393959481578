import * as moment from 'moment'
import { SaveProductItem, ProductItemList, UpdateImage, UpdateProductItem, AddProduct, UpdateProductImage } from '../../../../actions/SuppliersAPI';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../../../actions/auth';
import { connect, useDispatch } from 'react-redux';

import { CreateMessage } from "../../../Modals/ModalForm.js";
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../../assets/error.png';
import infoIcon from '../../../../assets/info.png';
import successIcon from '../../../../assets/success.png';
import warningIcon from '../../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';
import { findUnique } from '../../../../actions/APIHandler';

const ProductProfiles = ({ list, setList, SupplierID, ProductID, user, level, sub_level }) => {
    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [AddModalShow, setAddModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [Data, setData] = useState(false)
    const [SearchKey, setSearchKey] = useState(false)
    const [TempData, setTempData] = useState(false)
    const [Error, setError] = useState({});
    const [Step, setStep] = useState(null)
    const [ItemValue, setItemValue] = useState(false)
    const [Visibility, setVisibility] = useState("d-table");
    const [AccordLbl, setAccordLbl] = useState("Add New Product");
    const [Image, setImage] = useState(false)
    const [ImagePrv, setImagePrv] = useState(false)
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();

    const [formData, setFormData] = useState({
        id: "",
        Title: "",
        UnitWeight: null,
        UnitPrice: null,
        MRP: null,
        Type: null,
        Status: null
    });
    const { id, Title, UnitWeight, UnitPrice, MRP, Type, Status } = formData;

    useEffect(() => {
        LoadProductItems()
    }, [])

    const LoadProductItems = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var ProductItems = await ProductItemList(ProductID);
        if (ProductItems !== true)
            setData(ProductItems.data);

        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onBlur = (e) => {

        if (e.target.value === "") { setFormData({ ...formData, [e.target.name]: TempData, }); }
    }

    const handleFocus = (e) => {
        setTempData(e.target.value)
        e.target.select()
    };

    const Create_Product_Item = async e => {
        if (level === 8 || level === 9) {
            setCreateModalShow(false)
            setError({})
            dispatch({
                type: DISPLAY_OVERLAY,
                payload: true
            });
            e.preventDefault();
            const result = await SaveProductItem(SupplierID, ProductID, Title, UnitWeight, UnitPrice, MRP, Type, Status);

            if (result !== true) {
                if (result.error) {
                    const updatedState = {};
                    for (var pair of result.exception.entries()) {
                        updatedState[pair[1].field] = pair[1].message;
                        setError({
                            ...updatedState,
                        });
                    }
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Invalid',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: warningIcon
                    }])
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                } else {
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Success',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: successIcon
                    }])
                    LoadProductItems();
                    AccordionToggle();
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                }
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Error',
                    description: "Failed to save product item. Please try after some moment.",
                    backgroundColor: '#f0ad4e',
                    icon: errorIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
            dispatch({ type: DISPLAY_OVERLAY, payload: false });

        }
    };

    const Update_Product_Item = async e => {
        if (level === 8 || level === 9) {
            setUpdateModalShow(false)
            setError({})
            dispatch({
                type: DISPLAY_OVERLAY,
                payload: true
            });
            e.preventDefault();
            const result = await UpdateProductItem(SupplierID, ProductID, id, Title, UnitWeight, UnitPrice, MRP, Type, Status);

            if (result !== true) {
                if (result.error) {
                    const updatedState = {};
                    for (var pair of result.error_details.entries()) {
                        updatedState[pair[1].field] = pair[1].message;
                        setError({
                            ...updatedState,
                        });
                    }
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Invalid Data',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: warningIcon
                    }])
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                } else {
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Success',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: successIcon
                    }])
                    LoadProductItems();
                    AccordionToggle();
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                }
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Error',
                    description: "Failed to update product item. Please try after some moment.",
                    backgroundColor: '#f0ad4e',
                    icon: errorIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
            dispatch({ type: DISPLAY_OVERLAY, payload: false });

        }
    };

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: 0, boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", height: "25px", borderRadius: '0px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                borderRadius: '20px',
            };
        },
        menu: base => ({
            ...base,
            borderRadius: '0px',
            outline: 0,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            padding: '5px'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '25px',
            padding: '0 3px',
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        })
    };

    const ImageChange = (e) => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setImage(file)
            setImagePrv(reader.result)
        }

        reader.readAsDataURL(file)
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const ImageRemove = () => {
        setImagePrv(false)
    }

    const UploadImage = async e => {
        e.preventDefault();
        const result = await UpdateProductImage(ProductID, Image);
        if (result !== true) {
            if (result.error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                setList([...list, toastProperties = {
                    id: 1,
                    title: result.Title,
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: result.ico === 1 ? successIcon : result.ico === 2 ? infoIcon : result.ico === 3 ? warningIcon : result.ico === 4 ? errorIcon : null
                }])

            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: result.Title,
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: result.ico === 1 ? successIcon : result.ico === 2 ? infoIcon : result.ico === 3 ? warningIcon : result.ico === 4 ? errorIcon : null
                }])
            }
        } else {
            setList([...list, toastProperties = {
                id: 1,
                title: result.Title,
                description: result.message,
                backgroundColor: '#f0ad4e',
                icon: result.ico === 1 ? successIcon : result.ico === 2 ? infoIcon : result.ico === 3 ? warningIcon : result.ico === 4 ? errorIcon : null
            }])
        }
    };

    const AccordionToggle = () => {
        setFormData({
            id: "",
            Title: "",
            UnitWeight: "",
            UnitPrice: "",
            MRP: "",
            Type: null,
            Status: null
        });
        setStep(Step === "ProductAddition" ? null : "ProductAddition");
        setVisibility(Visibility === "d-none" ? "d-table" : "d-none")
        setAccordLbl("Add New Product")
    }

    const EditProduct = (item) => {
        setFormData(item)
        setFormData({
            id: item.id,
            Title: item.Title,
            UnitWeight: item.UnitWeight,
            UnitPrice: item.UnitPrice,
            MRP: item.MRP,
            Type: item.Type,
            Status: item.Status,
        });

        setStep(Step === "ProductAddition" ? null : "ProductAddition");
        setVisibility(Visibility === "d-none" ? "d-table" : "d-none")
        setAccordLbl("Update Product")
    }

    const Add_Product = async (e, item) => {
        if (level === 3 || level === 6) {
            setAddModalShow(false)
            setError({})
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            e.preventDefault();
            const result = await AddProduct(user.CompanyID, user.BranchID, SupplierID, item.id);

            if (result !== true) {
                if (result.error) {
                    const updatedState = {};
                    for (var pair of result.exception.entries()) {
                        updatedState[pair[1].field] = pair[1].message;
                        setError({
                            ...updatedState,
                        });
                    }
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Invalid Data',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: warningIcon
                    }])
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                } else {
                    setList([...list, toastProperties = {
                        id: 1,
                        title: result.Title,
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: result.ico === 1 ? infoIcon : successIcon
                    }])
                    LoadProductItems();
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                }
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Error',
                    description: "Failed to add product. Please try after some moment.",
                    backgroundColor: '#f0ad4e',
                    icon: errorIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        }
    };

    const PackList = [
        { value: 1, label: "Bolus" },
        { value: 2, label: "Bottle" },
        { value: 3, label: "Carton" },
        { value: 4, label: "Bag" },
        { value: 5, label: "Loose" },
        { value: 6, label: "Container" },
        { value: 7, label: "Aluminium Foil" },
        { value: 8, label: "Injectable/Vial" },
        { value: 9, label: "Paper Board" },
        { value: 10, label: "Paper" },
        { value: 11, label: "Lamitube" },
        { value: 11, label: "Box" },
        { value: 11, label: "Pack" },
        { value: 11, label: "Mini Pack" },
        { value: 12, label: "Casket" }
    ]

    const CScolourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: 0, boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", minWidth: "40vh", borderRadius: '20px' }),
        container: base => ({
            ...base,
            flex: 1,
        }),
    }

    let unique_search = Array.isArray(Data.ProItems) && Data.ProItems.length ? findUnique(Data.ProItems, d => d.Title) : null;

    let ProductList
    ProductList = Array.isArray(Data.ProItems) && Data.ProItems.length ? Data.ProItems.filter(function (item) {
        let BothValue = SearchKey ? item.id === SearchKey.value : true;
        return BothValue
    }).map(function ({ id, Title, Type, UnitWeight, UnitPrice, MRP, Status }) {
        return { id, Title, Type, UnitWeight, UnitPrice, MRP, Status };
    }) : null


    return (
        <div className="position-relative h-100">
            <div className="position-absolute overflow-auto my-1 w-100" style={{ height: "90%" }}>

                <div className="row justify-content-center mx-auto d-table w-100">
                    <div className="row align-items-center bg-white mx-0 py-2">
                        <div className="col-sm-5 col-md-6">
                            <p className="display-6 fw-bold d-flex justify-content-center m-0">
                                {Data ? Data.id + " | " + Data.Title : null}
                            </p>
                            {/* <img src={Data.Image ? process.env.REACT_APP_API_URL + Data.Image : process.env.REACT_APP_API_URL + "/Media/no_imge.jpg"} className="img-fluid rounded mb-0 mx-auto d-table" alt="avatar" style={{ width: "30%" }} /> */}
                            <div className="img_container">
                                <img src={ImagePrv ? ImagePrv : Data.Image ? process.env.REACT_APP_API_URL + Data.Image : ''} className="image img-fluid rounded-square mx-auto d-table" width="150" alt="avatar" style={{ width: "30%" }} />
                                {level === 7 || level === 8 || level === 9 ?
                                    <div className="middle">
                                        <input
                                            type="file"
                                            id="pic"
                                            className="d-none"
                                            accept="image/*"
                                            onChange={(e) => ImageChange(e)}
                                        />
                                        <label
                                            className='btn btn-outline-success shadow-lg'
                                            for="pic"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Choose Image"
                                        >
                                            <i class="fad fa-images"></i>
                                        </label>
                                        {ImagePrv ?
                                            <Fragment>
                                                <label
                                                    className='btn btn-outline-success shadow-lg'
                                                    for="file1"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    title="Upload Image"
                                                    onClick={(e) => UploadImage(e)}>
                                                    <i class="fad fa-upload"></i>
                                                </label>
                                                <button
                                                    className='btn btn-outline-success shadow-lg'
                                                    for="file1"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    title="Remove selected image"
                                                    type="button"
                                                    onClick={() => ImageRemove()}>
                                                    <i class="fad fa-minus-circle"></i>

                                                </button>
                                            </Fragment>
                                            : null}

                                    </div>
                                    : null
                                }
                            </div>
                            <p className="fs-6 fw-bold d-flex justify-content-center text-muted m-0">
                                {Data ? Data.Type.map((item) => item.label + " ") : null}
                            </p>
                        </div>
                        <div className="col-sm-5 col-md-6">
                            <p className="fs-6 fw-bold d-flex justify-content-center m-0">
                                {Data ? Data.Description : null}
                            </p>
                            <p className="fs-6 fw-bold d-flex justify-content-center m-0">
                                {Data ? Data.Specification : null}
                            </p>
                            <p className="fs-6 fw-bold d-flex justify-content-center m-0">
                                {Data ? Data.Status : null}
                            </p>
                        </div>
                    </div>
                    {
                        level === 8 || level === 9 ?
                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto d-table px-0">
                                <div className="accordion accordion-flush px-0" id="accordionFlushExample">
                                    <div className="accordion-item border-0 bg-transparent">
                                        <p className="accordion-header m-0" id="flush-headingOne">
                                            <button className={`cs_accordion-button ${Step === "ProductAddition" ? "collapse show" : "collapsed"}  shadow-none py-2 my-1 fs-4 fw-bold text-success bg-white shadow-sm`} style={{ borderLeft: "5px solid #28A745" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={Step === "ProductAddition" ? "true" : "false"} aria-controls="flush-collapseOne"
                                                id="ProductAddition" onClick={() => AccordionToggle()}>
                                                {AccordLbl}
                                            </button>
                                        </p>
                                        <div id="ProductAddition" className={`accordion-collapse collapse ${Step === "ProductAddition" ? "show" : null}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample" style={{}}>
                                            <div className="col-sm-12 col-md-12 col-lg-12 mx-auto d-table h-100">
                                                <div className="row mb-5">

                                                    <table className="table table-hover table-borderless">
                                                        <tbody className='w-100'>
                                                            <tr>
                                                                <td className="py-2" scope="row">Id</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2 d-flex">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='number'
                                                                        placeholder='Id'
                                                                        name='Id'
                                                                        value={id}
                                                                        onChange={e => onChange(e)}
                                                                        minLength='6'
                                                                        required
                                                                        disabled
                                                                    />
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td className="py-2">Title</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='text'
                                                                        placeholder='Title'
                                                                        name='Title'
                                                                        value={Title}
                                                                        onFocus={e => handleFocus(e)}
                                                                        onChange={e => onChange(e)}
                                                                        onBlur={(e) => onBlur(e)}
                                                                        maxLength='50'
                                                                        required
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Title ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Title}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                            <tr>
                                                                <td className="py-2" scope="row">Pack Type</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        closeMenuOnSelect={true}
                                                                        borderRadius={"0px"}
                                                                        options={PackList}
                                                                        name="Type"
                                                                        placeholder={"Please select products unit"}
                                                                        styles={colourStyles}
                                                                        value={Type ? { label: Type } : null}
                                                                        onChange={(e) => setFormData({ ...formData, Type: e.label })}
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Type ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Type}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }

                                                            <tr>
                                                                <td className="py-2" scope="row">Unit Weight</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='number'
                                                                        placeholder='Unit Weight'
                                                                        name='UnitWeight'
                                                                        value={UnitWeight}
                                                                        onChange={e => onChange(e)}
                                                                        onBlur={(e) => onBlur(e)}
                                                                        required
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.UnitWeight ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.UnitWeight}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }

                                                            <tr>
                                                                <td className="py-2" scope="row">Unit Price</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='number'
                                                                        placeholder='Unit Price'
                                                                        name='UnitPrice'
                                                                        value={UnitPrice}
                                                                        onChange={e => onChange(e)}
                                                                        onBlur={(e) => onBlur(e)}
                                                                        required
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.UnitPrice ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.UnitPrice}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }

                                                            <tr>
                                                                <td className="py-2" scope="row">MRP</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <input
                                                                        style={{ backgroundColor: "#F4F7FC", border: "0px solid #F4F7FC", fontWeight: "bold", width: "100%", paddingLeft: "5px" }}
                                                                        type='number'
                                                                        placeholder='MRP'
                                                                        name='MRP'
                                                                        value={MRP}
                                                                        onChange={e => onChange(e)}
                                                                        onBlur={(e) => onBlur(e)}
                                                                        required
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.MRP ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.MRP}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }

                                                            <tr>
                                                                <td className="py-2" scope="row">Status</td>
                                                                <td className="py-2">:</td>
                                                                <th className="py-2">
                                                                    <Select
                                                                        menuPortalTarget={document.body}
                                                                        closeMenuOnSelect={true}
                                                                        borderRadius={"0px"}
                                                                        options={[{ value: 1, label: "Available" }, { value: 2, label: "Unavilable" }, { value: 3, label: "Withdraw" }]}
                                                                        name="Status"
                                                                        placeholder={"Select product status"}
                                                                        styles={colourStyles}
                                                                        value={Status ? { label: Status } : null}
                                                                        onChange={(e) => setFormData({ ...formData, Status: e.label, })}
                                                                    />
                                                                </th>
                                                            </tr>
                                                            {Error.Status ?
                                                                <tr>
                                                                    <td colspan="3" className='p-0'>
                                                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Status}</small></p>
                                                                    </td>
                                                                </tr> : null
                                                            }
                                                        </tbody>

                                                    </table>

                                                    <div className="flex-1 text-center">
                                                        {
                                                            AccordLbl === "Update Product" ?
                                                                <button className='btn btn-outline-success form-rounded px-4  m-2' type='button' title="Update bank account" onClick={e => setUpdateModalShow(true)}>Update</button>
                                                                :
                                                                <button className='btn btn-outline-success form-rounded px-4' title="Add new bank account" type='button' onClick={e => setCreateModalShow(true)}>Submit</button>
                                                        }
                                                    </div>

                                                    {/* Add Bank Confirmation  */}
                                                    <CreateMessage
                                                        header="Add New Product Item"
                                                        body_header={Title}
                                                        body={"Are you sure want to add " + Title + "?"}
                                                        show={CreateModalShow}
                                                        Click={(e) => Create_Product_Item(e)}
                                                        onHide={() => setCreateModalShow(false)}
                                                    />

                                                    {/* Update Confirmation  */}
                                                    <CreateMessage
                                                        header="Update Item Info"
                                                        body_header={Title}
                                                        body={"Are you sure want to update " + Title + "?"}
                                                        show={UpdateModalShow}
                                                        Click={(e) => Update_Product_Item(e)}
                                                        onHide={() => setUpdateModalShow(false)}
                                                    />

                                                    {/* Delete Confirmation */}
                                                    {/* <CreateMessage
                                                header="Remove Bank Info"
                                                body_header={BankName}
                                                body={"Are you sure want to remove " + BankName + " " + BankBranchName + " Branch info?"}
                                                show={DeleteModalShow}
                                                Click={(e) => DeleteBank(e)}
                                                onHide={() => setDeleteModalShow(false)}
                                            /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                </div>
                <div className="row justify-content-center mx-auto d-table w-100 h-100">

                    <div className={`d-flex justify-content-between bg-white py-2 mt-2 border-bottom ${Visibility}`}>
                        <div className='my-auto text-center'>
                            <p className='display-6 fw-bold m-0 text-uppercase text-left'><Link className="fad fa-chevron-left px-2 text-dark text-decoration-none" to='#' onClick={() => history.goBack()}></Link> PRODUCT LISTS</p>
                        </div>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "10vh" }}>
                            <Select
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                borderRadius={"0px"}
                                // options={Data.map}
                                options={Array.isArray(unique_search) && unique_search.length ? unique_search.map((item) => ({ label: item.id + ". " + item.Title, value: item.id })) : []}
                                defaultValue={{ label: "Select Dept", value: 0 }}
                                name="Division"
                                placeholder={"Search"}
                                styles={CScolourStyles}
                                value={SearchKey}
                                onChange={(e) => setSearchKey(e)}
                                required
                                id="Title"
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                    </div>

                    <table className={`table table-hover table-borderless table-responsive card-1 d-table mt-1 ${Visibility}`}>
                        <thead>
                            <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">S/N</span> </th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Code</span> </th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Title</span> </th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Pack Type</span> </th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Unit Weight</span> </th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Unit Price</span> </th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">MRP</span> </th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Status</span> </th>
                                <th className="p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase">Action</span> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(ProductList) && ProductList.length ? ProductList.map((item, n) => (
                                    <tr className="border-bottom text-center" key={n}>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{n + 1}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.id}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0">{item.Title}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.Type}</span> </td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.UnitWeight}</span> </td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.UnitPrice}</span> </td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.MRP}</span> </td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.Status}</span> </td>
                                        <td className="p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">
                                            {
                                                level === 7 || level === 8 || level === 9 ?
                                                    <Fragment>
                                                        <button className="btn fs-4 px-2 py-0 fad fa-trash text-dark" />
                                                        <button className="btn fs-4 px-2 py-0 fad fa-edit text-dark" onClick={() => EditProduct(item)} />
                                                    </Fragment>
                                                    :
                                                    <button className="btn fs-4 px-2 py-0 fad fa-plus text-dark" onClick={() => { setItemValue(item); setAddModalShow(true) }} />

                                            }
                                        </span>
                                        </td>
                                    </tr>

                                ))
                                    :
                                    <tr className="text-center">
                                        <td colSpan="8" className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">
                                            No Product Items Found!
                                        </span></td>
                                    </tr>
                            }
                        </tbody>
                    </table>

                </div>
            </div >
            {ItemValue ?
                <CreateMessage
                    header="Add To My Product"
                    body_header={ItemValue.Title}
                    body={"Do you want to add " + ItemValue.Title + " in your product list?"}
                    show={AddModalShow}
                    Click={(e) => Add_Product(e, ItemValue)}
                    onHide={() => setAddModalShow(false)}
                /> : null}
        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
    level: state.auth.level,
    sub_level: state.auth.sub_level,
    list: props.list,
    setList: props.setList,
    SupplierID: props.match.params.sup_id,
    ProductID: props.match.params.id,
});

export default connect(mapStateToProps, { logout })(ProductProfiles);