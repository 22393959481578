import React, { useState, useEffect, Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch, Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Modal, Spinner } from "react-bootstrap";
import { logout, checkToken } from '../../../../actions/auth';
import { LoadProfile, LoadMyUsers, GetParty } from '../../../../actions/APIHandler';
import { BusinessPro, BISTerminate } from '../../../../actions/ContractAPI';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import { ContractUpdate } from '../../../Contract/ContractUpdate';
// import { BatchIssuance } from './BatchIssuance';
import { FaList, FaBorderAll } from "react-icons/fa";
import { AiOutlineScan } from "react-icons/ai";
import { Select } from 'semantic-ui-react';
import axios from 'axios';

import Batches from './Batches';
import Details from './Details';
import Bank from './Bank';
import Ladger from './Ladger/Ladger';
import Orders from './Orders';

var QRCode = require('qrcode.react');
const PartyMain = ({ display, CompanyID, BranchID, UserID, PartyID, list, setList }) => {
    const [BUp, setBUp] = useState(false);
    const [Item, setItem] = useState(false);

    const [Data, setData] = useState(null);
    const [UserData, setUserData] = useState(null);
    const [Active, setActive] = useState(false);
    const [ShowBatch, setShowBatch] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    let { path, url } = useRouteMatch();


    useEffect(() => {
        LoadParty();
    }, [])


    const LoadParty = async () => {
        var result = await GetParty(PartyID);
        if (result !== true) {
            setData(result);
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
            history.push('/my_party_list');
        }
    }

    return (
        <>
            <div className="header mb-0">
                <div className="d-flex justify-content-start overflow-auto bg-white mx-0 mb-2">
                    <div className="col-md-1 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "40px" }}>
                        <p className="fs-4 fw-bolder text-center m-0">{"0"}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Trans</p>
                    </div>

                    <div className="col-md-1 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "80px" }}>
                        <p className="fs-4 fw-bolder text-center m-0">{"0"}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Invoice</p>
                    </div>

                    <div className="col-md-2 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "80px" }}>
                        <p className="fs-4 fw-bolder text-center m-0">{"0.00"}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Purchase</p>
                    </div>

                    <div className="col-md-2 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "80px" }}>
                        <p className="fs-4 fw-bolder text-center text-uppercase m-0">{"0.00"}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Deposit</p>
                    </div>

                    <div className="col-md-1 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "80px" }}>
                        <p className="fs-4 fw-bolder text-center m-0">{"0.00"}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Withdraw</p>
                    </div>

                    <div className="col-md-1 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "80px" }}>
                        <p className="fs-4 fw-bolder text-center m-0">{0.00}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Balance</p>
                    </div>

                    <div className="col-md-1 text-md-center my-auto px-0 border-right" style={{ minWidth: "80px" }}>
                        <p className="fs-4 fw-bolder text-center text-uppercase m-0">{0}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Qunatity</p>
                    </div>

                    <div className="col-md-1 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "100px" }}>
                        <p className="fs-4 fw-bolder text-center m-0">{"0.000"}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Weight</p>
                    </div>

                    <div className="col-md-1 text-md-center my-auto px-0 text-center border-right" style={{ minWidth: "20px" }}>
                        <p className="fs-4 fw-bolder text-center m-0">{0.000}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">Size</p>
                    </div>

                    <div className="col-md-1 text-md-center my-auto px-0" style={{ minWidth: "80px" }}>
                        <p className="fs-4 fw-bolder text-center text-uppercase m-0">{0.00}</p>
                        <p className="fs-5 fw-bold m-0 text-uppercase text-center text-success bg-light">AVG Rate</p>
                    </div>
                </div>
            </div>

            <div className="item grid-group-item list-group-item m-0 px-0" style={{ borderLeft: "5px solid #ffc107", borderRight: "5px solid #ffc107", borderRadius: "10px" }}>
                <div className="d-flex justify-content-between align-items-center py-2 px-1">
                    <div className="fs-5 m-0 px-2">
                        <p className="display-6 fw-bold d-flex justify-content-start m-0">{Data ? Data.id + " | " + Data.Title : null}</p>
                        <p className="fs-5 fw-bold m-0">Address:</p>
                        <p className="fs-5 text-muted m-0">{Data ? Data.Address : null}</p>
                    </div>
                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "60px" }}>
                        {/* <div className="cs_inner"></div> */}
                    </div>
                    <p className="fs-5 m-0 px-2">
                        <p className="display-6 fw-bold text-right m-0">{Data ? Data.Name : null}</p>
                        <p className="fs-5 fw-bold text-right m-0">{Data ? Data.Contact : "N/A"}</p>
                    </p>
                </div>
            </div>

            <div className="header d-flex justify-content-center bg-white my-1">
                <div className="d-flex justify-content-start overflow-auto">
                    <Link className='fw-bold fs-5 text-success p-2' title="Transactions" type='button' to={`${url}/bis_ladger`}>Transactions</Link>

                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }}>
                        {/* <div className="cs_inner"></div> */}
                    </div>

                    {/* <Link className="fw-bold fs-5 text-success p-2" type='button' to={`${url}/orders`}>Order</Link>
                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }} /> */}
                    <Link className='fw-bold fs-5 text-success p-2' title="Agents" type='button' to={url}>Agents</Link>
                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }} />
                    <Link className="fw-bold fs-5 text-success p-2" type='button' to={`${url}/bank`}>Banks</Link>
                    <div className="cs_outer bg-light mx-2 my-auto" style={{ height: "30px" }} />
                    <Link className='fw-bold fs-5 text-success p-2' title="Details" type='button' to={`${url}/details`}>Details</Link>

                </div>
            </div>

            <Switch>
                <Route exact path={path}><Details UserID={UserID} PartyID={PartyID} list={list} setList={setList} /></Route>
                <Route exact path={`${path}/details`}> <Details UserID={UserID} PartyID={PartyID} list={list} setList={setList} /> </Route>
                {/* <Route exact path={`${path}/orders`}> <Orders UserID={UserID} PartyID={PartyID} list={list} setList={setList} /> </Route> */}
                <Route exact path={`${path}/bis_ladger`}> <Ladger UserID={UserID} PartyID={PartyID} BisData={Data} list={list} setList={setList} /> </Route>
                <Route exact path={`${path}/bank`}> <Bank UserID={UserID} PartyID={PartyID} list={list} setList={setList} /> </Route>
                {/* <Route render={(props) => <Redirect to="/not_found" />} /> */}
            </Switch>
        </>
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    UserID: props.match.params.id,
    PartyID: props.match.params.party_id,
    CompanyID: state.auth.user.D,
    BranchID: state.auth.user.BranchID
});

export default connect(mapStateToProps, { logout })(PartyMain);