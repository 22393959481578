import { DISPLAY_OVERLAY } from './types';
import axios from 'axios';
import { checkToken } from './auth';
import { useDispatch } from 'react-redux';

export const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "#F4F7FC",
        border: 0,
        boxShadow: "none",
        fontWeight: "bold",
        minHeight: "fit-content",
        height: "25px",
        borderRadius: "0px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: isDisabled ? "not-allowed" : "default",
            borderRadius: "20px",
        };
    },
    menu: (base) => ({
        ...base,
        borderRadius: "0px",
        outline: 0,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({
        ...base,
        padding: "5px",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "25px",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: "25px",
        padding: "0 3px",
        color: "black",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "black",
    }),
};

export const UpdateSupLogo = async (id, Image) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("id", id);
    formData.append("Logo", Image, Image.name);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_sup_logo/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const UpdateProductImage = async (id, Image) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("id", id);
    formData.append("Image", Image, Image.name);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_pro_image/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const NatSuppliers = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/nat_suppliers/`, config);
        return res
    } catch (err) {
        return true;
    }
}

export const GetSuppliers = async (id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_supplier/${id}`, config);
        return res.data[0]
    } catch (err) {
        return true;
    }
}

export const SaveSupplier = async (CmpName, Established, Products, CPFAddress, Contact, Email, Fax, Website, Status, Logo, LogoPrv) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const JsonProducts = JSON.stringify(Products);
    const formData = new FormData();
    formData.append("CmpName", CmpName);
    formData.append("Established", Established);
    formData.append("Products", JsonProducts);
    formData.append("CPFAddress", CPFAddress);
    formData.append("Contact", Contact);
    formData.append("Email", Email);
    formData.append("Fax", Fax);
    formData.append("Website", Website);
    formData.append("Status", Status);
    if (LogoPrv) { formData.append("Logo", Logo, Logo.name); }
    for (var pair of formData.entries()) {
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/supplier_reg/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveProductPro = async (SupplierID, Title, Description, Specification, Type, Category, Status, Image, ImagePrv) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const JsonType = JSON.stringify(Type);

    const formData = new FormData();
    formData.append("SupplierID", SupplierID);
    formData.append("Title", Title);
    formData.append("Description", Description);
    formData.append("Specification", Specification);
    formData.append("Type", JsonType);
    formData.append("Category", Category);
    formData.append("Status", Status);
    if (ImagePrv) { formData.append("Image", Image, Image.name); }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product_pro/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const ProductProList = async (id) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product_pro_list/${id}`, config);
        return res
    } catch (err) {
        return true;
    }
}

export const UpdateProductPro = async (SupplierID, ProductID, Title, Description, Specification, Type, Category, Status, Image, ImagePrv) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const JsonType = JSON.stringify(Type);
    const formData = new FormData();
    formData.append("SupplierID", SupplierID);
    formData.append("ProductID", ProductID);
    formData.append("Title", Title);
    formData.append("Description", Description);
    formData.append("Specification", Specification);
    formData.append("Type", JsonType);
    formData.append("Category", Category);
    formData.append("Status", Status);
    if (ImagePrv && Image) {
        formData.append("Image", Image, Image.name);
    }

    // for (var pair of formData.entries()) {
    //     
    // }

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/product_pro/${ProductID}/`, formData, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const SaveProductItem = async (SupplierID, ProductID, Title, UnitWeight, UnitPrice, MRP, Type, Status) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("SupplierID", SupplierID);
    formData.append("ProductID", ProductID);
    formData.append("Title", Title);
    formData.append("UnitWeight", UnitWeight);
    formData.append("UnitPrice", UnitPrice);
    formData.append("MRP", MRP);
    formData.append("Type", Type);
    formData.append("Status", Status);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product_item/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const ProductItemList = async (id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product_item_list/${id}`, config);
        return res
    } catch (err) {
        return true;
    }
}

export const UpdateProductItem = async (SupplierID, ProductID, id, Title, UnitWeight, UnitPrice, MRP, Type, Status) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("SupplierID", SupplierID);
    formData.append("ProductID", ProductID);
    formData.append("id", id);
    formData.append("Title", Title);
    formData.append("UnitWeight", UnitWeight);
    formData.append("UnitPrice", UnitPrice);
    formData.append("MRP", MRP);
    formData.append("Type", Type);
    formData.append("Status", Status);
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/product_item/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const AddSupplier = async (SupplierID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("SupplierID", SupplierID);
    try {
        // for (var pair of formData.entries()) {
        //     
        // }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/supplier_relation/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const MySuppliers = async (CompanyID, BranchID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_my_suppliers/${CompanyID}/${BranchID}`, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const AddProduct = async (CompanyID, BranchID, SupplierID, ItemCode) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("SupplierID", SupplierID);
    formData.append("ItemCode", ItemCode);
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/supplier_relation_item/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const MyProductList = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/sell_product_list/`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const SendOrder = async (CompanyID, BranchID, SupplierID, OrderNo, Amount, DDate, UpdatedBy, OrderData) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("SupplierID", SupplierID);
    formData.append("OrderNo", OrderNo);
    formData.append("Amount", Amount);
    formData.append("DeliveryDate", DDate);
    formData.append("Status", "Pending");
    formData.append("UpdatedBy", UpdatedBy);
    Object.keys(OrderData).map(e => {
        formData.append(`OrderMapData[${e}]ItemCode`, OrderData[e].ItemCode);
        formData.append(`OrderMapData[${e}]OrderQty`, OrderData[e].Quantity);
        formData.append(`OrderMapData[${e}]UnitPrice`, OrderData[e].UnitPrice);
    });

    // 
    try {
        // for (var pair of formData.entries()) {
        //     
        // }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/order/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const MyOrders = async (CompanyID, BranchID, SupplierID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_orders/${CompanyID}/${BranchID}/${SupplierID}`, config);
        // 
        return res
    } catch (err) {
        return true;
    }
}

export const LoadMyRep = async (CompanyID, BranchID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_my_rep/${CompanyID}/${BranchID}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const Purchase = async (CompanyID, BranchID, SupplierID, PurchaseNo, OrderNo, Date, Receiver, Payment, VatRate, Vat, Discount, Total, Paid, Due, Count, PurchaseData, Cash_ID, Cash_Code, Purchase_ID, Purchase_Code, Payable_ID, Payable_Code, Receivable_ID, Receivable_Code, SupplierTitle, Stock_ID, Stock_Code) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    let PaidTaka = parseInt(Payment) === 2 ? Total : parseInt(Payment) === 3 ? parseInt(Paid) : 0
    let DueTaka = parseInt(Payment) === 1 ? Total : parseInt(Payment) === 2 ? 0 : Due
    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("SupplierID", SupplierID);
    formData.append("PurchaseNo", PurchaseNo);
    formData.append("Date", Date);
    formData.append("Receiver", Receiver);
    formData.append("Payment", Payment);
    formData.append("VatRate", VatRate);
    formData.append("Vat", Vat);
    formData.append("Discount", Discount);
    formData.append("GrandTotal", Total);
    formData.append("PaidAmount", PaidTaka);
    formData.append("Due", DueTaka);
    formData.append("ItemCount", Count);
    formData.append("Purchase_ID", Purchase_ID);
    formData.append("Purchase_Code", Purchase_Code);
    formData.append("Payable_ID", Payable_ID);
    formData.append("Payable_Code", Payable_Code);
    formData.append("Receivable_ID", Receivable_ID);
    formData.append("Receivable_Code", Receivable_Code);
    formData.append("Stock_ID", Stock_ID);
    formData.append("Stock_Code", Stock_Code);

    Object.keys(PurchaseData).map(e => {
        formData.append(`PurchaseMapData[${e}]CompanyID`, CompanyID);
        formData.append(`PurchaseMapData[${e}]BranchID`, BranchID);
        formData.append(`PurchaseMapData[${e}]OrderNo`, OrderNo);
        formData.append(`PurchaseMapData[${e}]PurchaseNo`, PurchaseNo);
        formData.append(`PurchaseMapData[${e}]ReceiverID`, Receiver);
        formData.append(`PurchaseMapData[${e}]ItemCode`, PurchaseData[e].ItemCode);
        formData.append(`PurchaseMapData[${e}]Weight`, parseInt(PurchaseData[e].Weight));

        formData.append(`PurchaseMapData[${e}]Qty`, parseInt(PurchaseData[e].Quantity));
        formData.append(`PurchaseMapData[${e}]Rate`, parseInt(PurchaseData[e].UnitPrice));
        formData.append(`PurchaseMapData[${e}]SubTotal`, parseInt(PurchaseData[e].SubTotal));
        formData.append(`PurchaseMapData[${e}]Status`, "Received");
        formData.append(`PurchaseMapData[${e}]UpdatedBy`, Receiver);
    });

    formData.append('VoucherType', 6)
    formData.append('Dte', Date)
    formData.append('VoucherNo', PurchaseNo);
    formData.append('PaymentMethod', "Cash");
    formData.append('Consignee', "");

    if (parseInt(Payment) === 1) {
        formData.append('Payment', 1);
        formData.append('Count', 2)
        formData.append('Narration', "Being the goods trading worth BDT  " + Total.toLocaleString("en", { minimumFractionDigits: 2 }) + " credited to " + SupplierTitle + ". Purchase No- " + PurchaseNo)

        formData.append('VMapData[0]SLNo', 1)
        formData.append('VMapData[0]SupplierID', SupplierID)
        formData.append('VMapData[0]COA', Purchase_ID)
        formData.append('VMapData[0]COA_Code', Purchase_Code)
        formData.append('VMapData[0]DR', Total)
        formData.append('VMapData[0]CR', 0)

        formData.append('VMapData[1]SLNo', 2)
        formData.append('VMapData[1]SupplierID', SupplierID)
        formData.append('VMapData[1]COA', Payable_ID)
        formData.append('VMapData[1]COA_Code', Payable_Code)
        formData.append('VMapData[1]DR', 0)
        formData.append('VMapData[1]CR', Total)
    }
    else if (parseInt(Payment) === 2) {
        formData.append('Payment', 2);
        formData.append('Count', 2);
        formData.append('Narration', "Being the goods trading worth BDT " + Total.toLocaleString("en", { minimumFractionDigits: 2 }) + " on cash. Purchase No- " + PurchaseNo)

        formData.append('VMapData[0]SLNo', 1)
        formData.append('VMapData[0]SupplierID', SupplierID)
        formData.append('VMapData[0]COA', Purchase_ID)
        formData.append('VMapData[0]COA_Code', Purchase_Code)
        formData.append('VMapData[0]DR', Total)
        formData.append('VMapData[0]CR', 0)

        formData.append('VMapData[1]SLNo', 2)
        formData.append('VMapData[1]SupplierID', SupplierID)
        formData.append('VMapData[1]COA', Cash_ID)
        formData.append('VMapData[1]COA_Code', Cash_Code)
        formData.append('VMapData[1]DR', 0)
        formData.append('VMapData[1]CR', Total)
    }
    else if (parseInt(Payment) === 3) {
        formData.append('Payment', 3);
        formData.append('Count', 3);
        formData.append('Narration', "Being the goods trading worth BDT " + Total.toLocaleString("en", { minimumFractionDigits: 2 }) + " purchased in cash BDT " + PaidTaka.toLocaleString("en", { minimumFractionDigits: 2 }) + " and BDT " + DueTaka.toLocaleString("en", { minimumFractionDigits: 2 }) + " credited to " + SupplierTitle + ". Purchase No- " + PurchaseNo)
        formData.append('VMapData[0]SLNo', 1)
        formData.append('VMapData[0]SupplierID', SupplierID)
        formData.append('VMapData[0]COA', Purchase_ID)
        formData.append('VMapData[0]COA_Code', Purchase_Code)
        formData.append('VMapData[0]DR', Total)
        formData.append('VMapData[0]CR', 0)

        formData.append('VMapData[1]SLNo', 2)
        formData.append('VMapData[1]SupplierID', SupplierID)
        formData.append('VMapData[1]COA', Cash_ID)
        formData.append('VMapData[1]COA_Code', Cash_Code)
        formData.append('VMapData[1]DR', 0)
        formData.append('VMapData[1]CR', PaidTaka)

        formData.append('VMapData[2]SLNo', 3)
        formData.append('VMapData[2]SupplierID', SupplierID)
        formData.append('VMapData[2]COA', Payable_ID)
        formData.append('VMapData[2]COA_Code', Payable_Code)
        formData.append('VMapData[2]DR', 0)
        formData.append('VMapData[2]CR', DueTaka)
    }


    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/purchase/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchMySuppliers = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_supplier_list/`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const FetchMyBis = async (TypeNo) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_bis_list/${TypeNo}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const FetchMyEmp = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_emp_list/`, config);

        return res
    } catch (err) {
        return true;
    }
}
