import './clock.css'

import React, { Component } from 'react';

class Clock extends Component {
    constructor(props) {
        super(props);
        const d = new Date();
        const date = d.toLocaleDateString('en-GB', {
            weekday: 'short', day: 'numeric', month: 'short', year: 'numeric'
        }).replace(/ /g, ' ');
        this.state = { time: new Date().toLocaleTimeString(), date: date }
    }

    componentDidMount() {
        this.intervalID = setInterval(() => {
            this.updateClock()
        }, 1000)
    }

    // componentWillMount() {
    //     clearInterval(this.intervalID);
    // }

    updateClock() {
        const d = new Date();
        const date = d.toLocaleDateString('en-GB', {
            weekday: 'short', day: 'numeric', month: 'short', year: 'numeric'
        }).replace(/ /g, ' ');

        this.setState({ time: new Date().toLocaleTimeString(), date: date })
    }

    render() {
        return (
            <div className="d-flex align-items-end flex-column pl-2 border-left border-5 border-light" style={{ width: "200px" }}>
                <div className="Time text-right">
                    <p className="display-6 fw-bold m-0">{this.state.time}</p>
                </div>
                <p className="text-right m-0">{this.state.date}</p>
            </div>
        );
    }
}

export default Clock;