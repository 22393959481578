import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import { LoadMyFarms } from '../../../actions/ContractAPI';
import { FetchMySuppliers } from '../../../actions/SuppliersAPI';
import { LoadAccount, LoadMainAcc, LoadBanks, getBranch, VoucherSave, FetchVoucherNo } from '../../../actions/APIHandler';
import { connect, useDispatch } from 'react-redux';
import * as moment from 'moment'
import { DISPLAY_OVERLAY } from '../../../actions/types';
import { InfoMessage } from "../../Modals/ModalForm.js";
import Select from 'react-select';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';
import { customHeader, locales } from "../../Suppliers/Class/datepicker";

import Datepicker from 'react-datepicker';

// import required css from library
import "react-datepicker/dist/react-datepicker.css";

let today = new Date();

const Voucher = ({ display, BisID, CompanyID, BranchID, user, list, setList }) => {
    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const [InfoModalShow, setInfoModalShow] = useState(false);
    const [Fram, setFram] = useState(null)
    const [ActiveBatch, setActiveBatch] = useState(false)
    const [Data, setData] = useState(false)
    const [Error, setError] = useState({});
    const [MyProList, setMyProList] = useState(false)

    const [InvoiceNo, setInvoiceNo] = useState(0)

    const [Reference, setReference] = useState("")
    const [VoucherType, setVoucherType] = useState(null)
    const [Date, setDate] = useState(today)
    const [VoucherNo, setVoucherNo] = useState(0)
    const [Name, setName] = useState(null)
    const [PaymentMethod, setPaymentMethod] = useState(null)
    const [Narration, setNarration] = useState(null)
    const [Count, setCount] = useState(1)

    const [AccTypes, setAccTypes] = useState(null)
    const [Journal, setJournal] = useState(null)
    const [CMPJournal, setCMPJournal] = useState(null)
    const [Percent, setPercent] = useState(null)

    const initialValue = { value: 0, label: "" };
    const [BankLists, setBankLists] = useState(initialValue)
    const [BBLists, setBBLists] = useState(initialValue)
    const [TempData, setTempData] = useState(false)

    const [Vat, setVat] = useState(0.00)
    const [Discount, setDiscount] = useState(0.00)
    const [Shipment, setShipment] = useState(0.00)
    const [Total, setTotal] = useState(0.00)
    const [NetTotal, setNetTotal] = useState(0.00)
    const [locale, setLocale] = useState('en');

    const [AccData, setAccData] = useState([])

    const [AccLists, setAccLists] = useState(null)
    const [ContractLists, setContractLists] = useState(null)
    const [SupplierLists, setSupplierLists] = useState(null)

    let toastProperties = null;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        UserID: "",
        BisID: "",
        BatchID: "",
        SupplierID: "",

        AccountTitle: "",
        AccountID: "",
        COA_Code: "",
        COA_ID: "",

        COA_ID: "",
        SLNo: "",
        Debit: "",
        Credit: "",

        BankID: "",
        BankName: "",
        ACName: "",
        ACNo: "",
        ChequeNo: "",
    });
    const { AccountID, AccountTitle, COA_Code, COA_ID, SLNo, Debit, Credit, BankID, BankName, Branch, ACName, ACNo, ChequeNo } = formData;
    useEffect(() => {
        LoadAccounts();
    }, [])

    const LoadVoucherNo = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        const Type = VoucherType.value === 1 || VoucherType.value === 2 ? "RV" : VoucherType.value === 3 || VoucherType.value === 4 ? "PV" : VoucherType.value === 5 ? "CV" : VoucherType.value === 6 ? "JV" : false;
        if (Type) {
            var result = await FetchVoucherNo(Type);

            if (result !== true) {
                setVoucherNo(result)
            } else {
                // history.push('/farm_lists');
            }
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const CScolourStyles = {
        container: base => ({
            ...base,
            flex: 1,
            fontWeight: "500"
        }),
    }

    const AddRow = (e) => {
        e.preventDefault()
        setAccData([...AccData, formData]);
        setCount(Count + 1);
        setFormData({
            AccountID: "",
            UserID: "",
            BusinessID: "",
            BatchID: "",
            SupplierID: "",
            COA_ID: "",
            SLNo: "",
            Description: "",
        });
        setAccTypes(null)
    }

    const getTotal = () => {
        let TotalDebit = 0.00;
        let TotalCredit = 0.00;
        const Debit = AccData.map(row => parseInt(row.Debit));
        const Credit = AccData.map(row => parseInt(row.Credit));

        if (Debit.length > 0 && Credit.length > 0) {
            TotalDebit = Debit.reduce((acc, val) => acc + val);
            TotalCredit = Credit.reduce((acc, val) => acc + val);
        }
        var Total = { "Debit": TotalDebit, "Credit": TotalCredit }
        return Total
    }

    const deleteRow = (i) => {
        const newRows = AccData.splice(i, 1).concat(AccData.slice(i + 1));
        setCount(Count - 1)
    };

    const shouldBlur = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            AddRow();
        }
    }

    const history = useHistory();

    const SaveVoucher = async () => {
        if (user !== null) {
            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var data = moment(Date).format('YYYY-MM-DD')
            var result = await VoucherSave(VoucherType, data, VoucherNo, PaymentMethod, Name, Narration, parseInt(Count) - 1, AccData);

            if (result !== true) {
                if (result.error) {
                    const updatedState = {};
                    for (var pair of result.exception.entries()) {
                        updatedState[pair[1].field] = pair[1].message;
                        setError({ ...updatedState });
                    }
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Invalid Data',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: warningIcon
                    }])
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                } else {
                    setList([...list, toastProperties = {
                        id: 1,
                        title: result.Title,
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: result.ico === 1 ? infoIcon : successIcon
                    }])
                    setReference(null);
                    LoadVoucherNo();
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                }
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Error',
                    description: "Failed to save voucher. Please try after some moment.",
                    backgroundColor: '#f0ad4e',
                    icon: errorIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }

            dispatch({ type: DISPLAY_OVERLAY, payload: false });

        } else {
            history.push('/my_supplier');
        }
    }

    const VatCalc = (e) => {
        setVat(e.target.value)
        let SubTotal = getTotal();
        let Vat = SubTotal + ((SubTotal * e.target.value) / 100)
        let Ship = Number(Vat) + Number(Shipment);
        setNetTotal(Vat);
        setTotal(Ship);
    }

    const DiscountCalc = (e) => {
        setDiscount(e.target.value)
        let SubTotal = getTotal();
        let VatCal = SubTotal + ((SubTotal * Vat) / 100);
        let Discount = Number(VatCal) - Number(e.target.value);
        let Ship = Number(VatCal) - Number(e.target.value) + Number(Shipment);
        setNetTotal(Discount);
        setTotal(Ship);
    }

    const ShippingCalc = (e) => {
        setShipment(e.target.value)
        let VatCal = NetTotal === 0.00 ? getTotal() + Number(e.target.value) : NetTotal + Number(e.target.value)
        setTotal(VatCal);
    }

    const update = (e) => {
        e.parentElement.setAttribute('data-value', e.value)

    }

    const LoadRefAcc = async (e) => {
        setContractLists(null)
        setAccTypes(e)
        if (e.value === 2) {
            setSupplierLists(false)
            var result = await LoadMyFarms();
            setContractLists(result.farms)

        }
        else if (e.value === 5) {
            setContractLists(false);
            var result = await FetchMySuppliers();
            setSupplierLists(result.data)

        }
    }

    const LoadSuppliers = async (e) => {
        setContractLists(null)
        setAccTypes(e)
        if (e.value === 2) {
            var result = await LoadMyFarms();
            setContractLists(result.farms)

        }
    }

    const LoadAccounts = async (e) => {
        setAccLists(null)
        var result = await LoadAccount();
        setAccLists(result.data)
    }

    const LoadJaurnal = async (e) => {
        const Type = e.value === 1 || e.value === 2 ? "RV" : e.value === 3 || e.value === 4 ? "PV" : e.value === 5 ? "CV" : e.value === 6 ? "JV" : false;
        if (Type) {
            var result = await FetchVoucherNo(Type);

            if (result !== true) {
                setVoucherNo(result)
                setVoucherType(e)
                setJournal(null)
                if (e.value === 1 || e.value === 2 || e.value === 3 || e.value === 4) {
                    var result = await LoadMainAcc(e.value === 1 ? 1 : e.value === 2 ? 1 : e.value === 3 ? 1 : e.value === 4 ? 1 : null);

                    setCMPJournal(result.Acc);
                }
                if (e.value === 2 || e.value === 4 || e.value === 5 || e.value === 6)
                    getBanks();
                setPaymentMethod(e.value === 2 || e.value === 4 ? "Bank" : "Cash")
            } else {
                // history.push('/farm_lists');
            }
        }
    }

    const AccItems = [
        { value: 1, label: "Company A/C" },
        { value: 2, label: "Contract A/C" },
        { value: 3, label: "Layer A/C" },
        { value: 4, label: "Customer A/C" },
        { value: 5, label: "Supplier A/C" },
        { value: 6, label: "Party A/C" }
    ]

    const JournalList = [
        { value: 1, label: "Cash Received" },
        { value: 2, label: "Bank Received" },
        { value: 3, label: "Cash Payment" },
        { value: 4, label: "Bank Payment" },
        { value: 5, label: "Contra" },
        { value: 6, label: "Journal" }
    ]

    const getBanks = async () => {
        const BList = await LoadBanks()
        setBankLists(BList)
    }

    const LoadBankBranch = async (e) => {
        setFormData({ ...formData, "BankName": e.label });
        const BBList = await getBranch(e.label)
        if (BBList.data) {
            setBBLists(BBList.data)
        } else {
            setBBLists(false)
        }
    }

    const HandleBank = () => {
        if (!Data || !Data.data[0]) {
            setFormData({
                ...formData,
                AccName: false,
                AccNumber: false,
                BanckName: "",
                BankBranchName: "",
                Address: "",
                Telephone: "",
                BankID: "",
                Status: 2,
            })
        }

        getBanks();
        setTempData(null);
        setError([]);
        // setStep(Step === "BankAcc" ? null : "BankAcc");
    }

    const BankBranchHandler = (e) => {
        setFormData({
            ...formData,
            "BankBranchName": e.label,
            "BankID": e.value,
            "Address": e.Address,
            "Telephone": e.Telephone,
        })
    }

    const GenVoucheArr = (e) => {
        setFormData({
            COA_Code: e.COA_Code,
            COA_ID: e.value,

            UserID: AccTypes ? AccTypes.value === 2 ? Reference.UserID : "" : "",
            BusinessID: AccTypes ? AccTypes.value === 2 ? Reference.BusinessID : "" : "",
            BatchID: AccTypes ? AccTypes.value === 2 || AccTypes.value === 1 ? Reference.value : "" : "",
            SupplierID: AccTypes ? AccTypes.value === 5 ? Reference.value : "" : "",

            SLNo: Count,
            AccountTitle: e.label,
        })
    }

    return (
        <div className="row h-100 m-0 d-flex justify-content-center">
            <div className="header mb-2">
                <p className="display-6 d-flex justify-content-center m-0">
                    Vouchers
                </p>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb d-flex justify-content-center mb-2">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/voucher">Payments/Debit vouchers</Link></li>
                    </ol>
                </nav>
            </div>
            <div className="col-lg-12 h-100 p-0">

                <div div className="row d-flex bg-white mx-auto">
                    <div className="col-lg-5 d-flex flex-wrap align-items-center justify-content-center">
                        <div className="row w-100 py-3">
                            <p className="fs-6 text-success text-center fw-bold m-0">Voucher Types</p>
                            <div className="col-sm-12 px-2">
                                <Select
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    borderRadius={"0px"}
                                    options={JournalList}
                                    name="Division"
                                    placeholder={"Please select voucher type"}
                                    styles={CScolourStyles}
                                    value={VoucherType ? VoucherType : null}
                                    onChange={(e) => LoadJaurnal(e)}
                                    required
                                    id="Title"
                                />
                            </div>

                            <label className="fs-6 text-success text-center fw-bold mt-2 mb-0">Transaction To</label>
                            <div className="col-sm-12 px-2">
                                <Select
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    borderRadius={"0px"}
                                    options={AccItems}
                                    name="AccItems"
                                    placeholder={"Please select transaction a/c"}
                                    styles={CScolourStyles}
                                    value={AccTypes}
                                    onChange={(e) => LoadRefAcc(e)}
                                    required
                                    id="AccTypes"
                                />
                            </div>

                            {AccTypes !== null ? AccTypes.value !== 1 ?
                                <>
                                    <label className="fs-6 text-success text-center fw-bold mt-2 mb-0">{`Select ${AccTypes.label}`}</label>
                                    <div className="col-sm-12 px-2">
                                        {/* <input type="text" className="form-control p-0 text-center" id="specificSizeInputGroupUsername" placeholder="Username" /> */}
                                        <Select
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                            menuPortalTarget={document.body}
                                            borderRadius={"0px"}
                                            options={Array.isArray(ContractLists) && ContractLists.length ?
                                                ContractLists.map((item) => ({ label: item.Title, value: item.BatchID, BusinessID: item.FarmID, UserID: item.UserID }))
                                                : Array.isArray(SupplierLists) && SupplierLists.length ? SupplierLists : []
                                            }
                                            // options={AccLists ? AccLists :
                                            //     Array.isArray(ContractLists) && ContractLists.length ?
                                            //         ContractLists.map((item) => ({ label: item.Title, value: item.BatchID, BusinessID: item.FarmID, UserID: item.UserID })) : []
                                            // }
                                            name="Consignee"
                                            placeholder={"Please select consignee"}
                                            styles={CScolourStyles}
                                            value={Reference}
                                            onChange={(e) => setReference(e)}
                                            required
                                            id="Consignee"
                                        // isDisabled={Percent ? false : true}
                                        />
                                    </div>
                                </> : null : null}
                        </div>

                    </div >
                    <div className="col-lg-1">
                        <div className="cs_outer" style={{ height: "100%" }}>
                            <div className="cs_inner"></div>
                        </div>
                    </div>

                    <div className="col-lg-6 d-flex flex-wrap align-items-center justify-content-center">
                        <form className="w-100">
                            <div className="row mb-3 mt-3">
                                <label className="col-sm-3 col-form-label text-success fw-bold">Date</label>
                                <div className="col-sm-9">
                                    <Datepicker
                                        selected={Date}
                                        className="form-control fw-bold"
                                        dateFormat="dd MMM yyyy"
                                        onChange={(e) => setDate(e)}
                                        renderCustomHeader={props => customHeader({ ...props, locale })}
                                        locale={locales[locale]}
                                        placeholderText="Please select date"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label text-success fw-bold">Voucher No</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control fw-bold"
                                        id="VoucherNo"
                                        value={VoucherNo}
                                        // onChange={(e) => setVoucherNo(e.target.value)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label text-success my-auto fw-bold">Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control fw-bold"
                                        id="Name"
                                        value={Name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label text-success my-auto fw-bold">Narration</label>
                                <div className="col-sm-9">
                                    <textarea
                                        rows="2"
                                        type="text"
                                        className="form-control fw-bold"
                                        id="Narration"
                                        value={Narration}
                                        onChange={(e) => setNarration(e.target.value)}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {
                    VoucherType ? VoucherType.value === 2 || VoucherType.value === 4 || VoucherType.value === 5 ?
                        <form className="row gx-3 bg-white justify-content-between align-items-center m-0 p-2 my-1">
                            <div className="col-sm-4" disabled>
                                <p className="text-center text-dark fw-bold m-0 border-bottom">Bank</p>
                                <div className="input-group fs-5 fw-bold">
                                    {/* <input type="text" className="form-control p-0 text-center" id="specificSizeInputGroupUsername" placeholder="Username" /> */}
                                    <Select
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        menuPortalTarget={document.body}
                                        borderRadius={"0px"}
                                        options={BankLists ? BankLists : null}
                                        name="BankName"
                                        placeholder={"Please select bank"}
                                        styles={CScolourStyles}
                                        value={BankName ? { label: BankName } : null}
                                        onChange={(e) => LoadBankBranch(e)}
                                        required
                                        id="BankName"
                                    />
                                </div>
                            </div>
                            <div className="col-sm-2" disabled>
                                <p className="text-center text-dark fw-bold m-0 border-bottom">Branch</p>
                                <div className="input-group fs-5 fw-bold">
                                    <Select
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        menuPortalTarget={document.body}
                                        borderRadius={"0px"}
                                        options={BBLists}
                                        name="Branch"
                                        placeholder={"Please select branch"}
                                        styles={CScolourStyles}
                                        value={Branch ? { label: Branch } : null}
                                        onChange={e => setFormData({ ...formData, "BankID": e.value, "Branch": e.label })}
                                        required
                                        id="Branch"
                                    />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <p className="text-center text-dark fw-bold m-0 border-bottom">A/C Name</p>
                                <input
                                    type="text"
                                    className="form-control fs-5 fw-bold p-0 text-center"
                                    name="ACName"
                                    id="ACName"
                                    placeholder="ACName"
                                    value={ACName}
                                    onChange={(e) => setFormData({ ...formData, "ACName": e.target.value })}
                                    // onKeyDown={(e) => shouldBlur(e)}
                                    required
                                />
                            </div>
                            <div className="col-sm-2">
                                <p className="text-center text-dark fw-bold m-0 border-bottom">A/C Number</p>
                                <input
                                    type="number"
                                    className="form-control fs-5 fw-bold p-0 text-center"
                                    name="ACNo"
                                    id="ACNo"
                                    placeholder="ACNo"
                                    value={ACNo}
                                    onChange={(e) => setFormData({ ...formData, "ACNo": e.target.value })}
                                    // onKeyDown={(e) => shouldBlur(e)}
                                    required
                                />
                            </div>
                            <div className="col-sm-2">
                                <p className="text-center text-dark fw-bold m-0 border-bottom">Cheque No</p>
                                <input
                                    type="number"
                                    className="form-control fs-5 fw-bold p-0 text-center"
                                    name="ChequeNo"
                                    id="ChequeNo"
                                    placeholder="ChequeNo"
                                    value={ChequeNo}
                                    onChange={(e) => setFormData({ ...formData, "ChequeNo": e.target.value })}
                                    // onKeyDown={(e) => shouldBlur(e)}
                                    required
                                />
                            </div>
                        </form>
                        :
                        null
                        :
                        null
                }

                <div className="row justify-content-center mx-auto d-table w-100 h-100">
                    <form className="row gx-3 bg-white justify-content-between align-items-center m-0 p-2 my-1">
                        <div className="col-sm-2">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Code</p>
                            <input
                                type="number"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                id="ItemCode"
                                placeholder="Code"
                                value={COA_Code ? COA_Code : ""}
                                required
                                disabled={Percent ? false : true}
                            />
                        </div>
                        <div className="col-sm-5" disabled>
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Select Accounts</p>
                            <div className="input-group fs-5 fw-bold">
                                {/* <input type="text" className="form-control p-0 text-center" id="specificSizeInputGroupUsername" placeholder="Username" /> */}
                                <div className="col-sm-12 px-0">
                                    <Select
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        menuPortalTarget={document.body}
                                        borderRadius={"0px"}
                                        options={AccLists ? AccLists : null}
                                        name="AccItems"
                                        placeholder={"Please select company"}
                                        styles={CScolourStyles}
                                        value={AccountTitle ? { label: AccountTitle, value: AccountID } : null}
                                        onChange={(e) => GenVoucheArr(e)}
                                        required
                                        id="AccTypes"
                                    />
                                </div>



                                {/* <Select
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    borderRadius={"0px"}
                                    options={AccLists ? AccLists :
                                        Array.isArray(ContractLists) && ContractLists.length ?
                                            ContractLists.map((item) => ({ label: item.Title, value: item.BatchID, BusinessID: item.FarmID, UserID: item.UserID })) : []
                                    }
                                    name="Consignee"
                                    placeholder={"Please select consignee"}
                                    styles={CScolourStyles}
                                    value={Consignee}
                                    onChange={(e) => GenVoucheArr(e)}
                                    required
                                    id="Consignee"
                                // isDisabled={Percent ? false : true}
                                /> */}
                            </div>
                        </div>



                        <div className="col-sm-2">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Debit</p>
                            <input
                                type="text"
                                id="Debit"
                                name="Debit"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                placeholder="Debit"
                                value={Debit ? Debit.toLocaleString("en", { minimumFractionDigits: 2 }) : ""}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                // disabled={Percent ? false : true}
                                required
                            />
                        </div>
                        <div className="col-sm-2 border-right border-2">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Credit</p>
                            <input
                                type="text"
                                id="Credit"
                                name="Credit"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                placeholder="Credit"
                                value={Credit ? Credit.toLocaleString("en", { minimumFractionDigits: 2 }) : ""}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                // disabled={Percent ? false : true}
                                required
                            />
                        </div>

                        <div className="col-auto">
                            <p className="text-center text-dark fw-bold m-0" />
                            <button className="btn fs-3 p-0 text-success"
                                onClick={(e) => AddRow(e)}
                            >
                                <i className="fad fa-plus"></i>
                            </button>
                        </div>
                    </form>

                    <InfoMessage
                        header="Invalid Data"
                        body_header="Input data is not valid. Please fill input field correctly."
                        body="Please fill all field correctly"
                        show={InfoModalShow}
                        onHide={() => setInfoModalShow(false)}
                    />

                    {
                        Array.isArray(AccData) && AccData.length ?
                            <table className={`table table-hover table-borderless table-responsive card-1 d-table`}>
                                <thead>
                                    <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                        <th className="py-2 border-right"><span>S/N</span></th>
                                        <th className="py-2 border-right"><span>Description</span></th>
                                        <th className="py-2 border-right"><span>Debit</span></th>
                                        <th className="py-2 border-right"><span className="d-block text-right fw-bolder">Credit</span></th>
                                        <th className="py-2"><span>Action</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        AccData.map((item, i) => (
                                            <tr className="border-bottom text-center" key={i}>
                                                <td className="p-0 border-right"><span className="d-block fw-bold">{item.SLNo}</span></td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold text-left px-2">{item.AccountTitle}</span></td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold text-right px-2">{parseInt(item.Debit).toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                                <td className="p-1 border-right"><span className="d-block fw-bold text-right px-2">{parseInt(item.Credit).toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                                <td className="p-0">
                                                    <button className="btn fs-3 p-0 text-danger" onClick={() => deleteRow(i)}>
                                                        <i className="fad fa-minus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="text-center border border-light mt-3">
                                        <td className="p-1" colSpan="2"><span className="d-block text-right fw-bold">Total:</span> </td>
                                        <td className="p-1"><span className="d-block text-right fw-bolder">{getTotal().Debit.toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                        <td className="p-1"><span className="d-block fw-bolder text-right">{getTotal().Credit.toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                        <td className="px-3 py-0">
                                            <button type="button" className="btn fs-3 p-0 text-success"
                                                onClick={() => SaveVoucher()}
                                                disabled={getTotal().Debit === getTotal().Credit ? false : true}
                                            >
                                                <i className="fad fa-paper-plane px-2"></i>
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            :
                            <div className={`d-flex justify-content-center align-items-center bg-white`}>
                                <p className='fs-2 fw-bold text-center text-success m-0'>No Product Found!</p>
                            </div>
                    }
                </div>


            </div>

        </div >
    )
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    BisID: props.match.params.id
});

export default connect(mapStateToProps, { logout })(Voucher);