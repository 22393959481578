import React, { useState, useEffect, Fragment } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import { FetchDispatch, DispatchInvoiceDelete, FetchSellReport, FetchPurchaseReport } from '../../../actions/ContractAPI';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../actions/types';
import * as moment from 'moment'
import Select from 'react-select';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';

import { exportPDF } from '../../Suppliers/Class/OrderPDF';
import { Search } from 'semantic-ui-react';

import { UpdateModal, DeleteModal, InfoMessage } from "./Modals/ModalForm.js";
import { customHeader, locales } from "../../Suppliers/Class/datepicker";
import Datepicker from 'react-datepicker';
import { findUnique } from '../../../actions/APIHandler';
let today = new Date();
const PurchaseReport = ({ CompanyID, BranchID, SupplierID, user, list, setList }) => {
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const [Date, setDate] = useState(today)
    const [Data, setData] = useState(false)
    const [DeleteData, setDeleteData] = useState(false)
    const [StockItem, setStockItem] = useState(false)

    const [BranchFilter, setBranchFilter] = useState(null);
    const [SupFilter, setSupFilter] = useState(null);
    const [CategoryFilter, setCategoryFilter] = useState(null);
    const [SearchKey, setSearchKey] = useState(null)

    const [locale, setLocale] = useState('en');

    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        LoadDispatch();
    }, [])

    const LoadDispatch = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var result = await FetchPurchaseReport(moment(today).format("YYYY-MM-DD"));
        if (result !== true) {
            setData(result.data);
        } else {
            // history.push('/not_found');
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const DateHandler = async (e) => {
        let date = moment(e).format("YYYY-MM-DD");
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var result = await FetchPurchaseReport(date);
        if (result !== true) {
            setData(result.data);
        } else {
            // history.push('/not_found');
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
        setDate(e)
    }

    const DeleteInvoice = async (e, id) => {
        setDeleteModalShow(false)
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        e.preventDefault();
        const result = await DispatchInvoiceDelete(id);
        if (result !== true) {
            if (result.user_error) {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Not Found/Invalid Invoice',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: infoIcon
                }])
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Success!',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                LoadDispatch();
            }
        } else {
            setList([...list, toastProperties = {
                id: 1,
                title: 'Error!',
                description: "Failed to delete invoice. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    };


    const CScolourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: 0, boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", borderRadius: '20px' }),
        container: base => ({
            ...base,
            flex: 1,
        }),
    }

    let unique = Array.isArray(Data) && Data.length ? findUnique(Data, d => d.BranchID) : null;

    let SaleList
    SaleList = Array.isArray(Data) && Data.length ? Data.filter(function (item) {
        let BothValue = BranchFilter && SupFilter && CategoryFilter && SearchKey ? item.BranchID === BranchFilter.value && item.SupplierID === SupFilter.value && item.Category === CategoryFilter.value && item.id === SearchKey.value :
            BranchFilter && SupFilter && CategoryFilter ? item.BranchID === BranchFilter.value && item.SupplierID === SupFilter.value && item.Category === CategoryFilter.value :
                BranchFilter && SupFilter ? item.BranchID === BranchFilter.value && item.SupplierID === SupFilter.value :
                    BranchFilter && CategoryFilter ? item.BranchID === BranchFilter.value && item.Category === CategoryFilter.value :
                        BranchFilter ? item.BranchID === BranchFilter.value :

                            BranchFilter ? item.BranchID === BranchFilter.value :
                                SupFilter ? item.SupplierID === SupFilter.value :
                                    CategoryFilter ? item.Category === CategoryFilter.value :
                                        SearchKey ? item.ItemCode === SearchKey.value : true;

        return BothValue
    }).map(function ({ id, Date, ItemCode, Category, Details, Qty, Weight, Rate, Price, Payment, GrandTotal, PurchaseNo, SupplierID, SupplierTitle, BranchID, BranchName, Reciever, Count }) {
        return { id, Date, ItemCode, Category, Details, Qty, Weight, Rate, Price, Payment, GrandTotal, PurchaseNo, SupplierID, SupplierTitle, BranchID, BranchName, Reciever, Count };
    }) : null

    let unique_search = Array.isArray(SaleList) && SaleList.length ? findUnique(SaleList, d => d.Details) : null;
    let unique_category = Array.isArray(SaleList) && SaleList.length ? findUnique(SaleList, d => d.Category) : null;
    let unique_sup = Array.isArray(SaleList) && SaleList.length ? findUnique(SaleList, d => d.SupplierTitle) : null;

    const Summuation = (arr) => {
        var result = [];
        arr.reduce(function (res, val) {
            if (!res[val.Category]) {
                res[val.Category] = { Category: val.Category, Qty: 0, Weight: 0 };
                result.push(res[val.Category])
            }
            res[val.Category].Qty += parseFloat(val.Qty, 10)
            res[val.Category].Weight += parseFloat(val.Weight, 10)
            return res;
        }, {});
        return Array.isArray(result) && result.length ? result.map((item, i) => (
            <p className='text-dark fs-4 fw-bold m-0 border border-light px-2' style={{ borderRadius: "15px" }}>{item.Category + ": " + item.Qty.toLocaleString("en-BD", { minimumFractionDigits: 2 }) + "PCS & " + item.Weight.toLocaleString("en-BD", { minimumFractionDigits: 3 }) + "KG"}</p>
        )) : null;
    }

    return (
        <div className="row h-100 m-0 d-flex justify-content-center">
            <div className="col-lg-12 h-100 px-0">
                {/* ORDER HISTORY */}
                <div className="row d-flex bg-white mx-auto my-2 py-1 m-0">

                    <div className="d-flex justify-content-between align-items-center bg-white p-0">
                        <p className='fs-3 bg-white fw-bolder text-dark my-0 px-2'>PURCHASE REPORTS</p>
                        <div className="d-flex justify-content-around mx-2">
                            <p className='text-dark fs-4 fw-bold m-0 border border-light px-2' style={{ borderRadius: "15px" }}>{Array.isArray(SaleList) && SaleList.length ? "Items: " + SaleList.length.toLocaleString("en-BD", { minimumFractionDigits: 0 }) : null}</p>
                            {Array.isArray(SaleList) && SaleList.length ? Summuation(SaleList) : null}
                        </div>
                        <div className="d-flex justify-content-end mx-2">
                            <Datepicker
                                selected={Date}
                                className="form-control fw-bold round_radius50px text-center p-0 m-0"
                                dateFormat="dd MMM yyyy"
                                onChange={(e) => DateHandler(e)}
                                renderCustomHeader={props => customHeader({ ...props, locale })}
                                locale={locales[locale]}
                                placeholderText="Date"
                            />
                        </div>
                    </div>
                </div>


                <div className="row d-flex bg-white mx-auto my-2 py-1 m-0">
                    <div className="d-flex justify-content-around align-items-center bg-white p-0">
                        <div className="d-flex justify-content-center mx-2" style={{ minWidth: "22%" }}>
                            <Select
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                borderRadius={"0px"}
                                // options={Data.map}
                                options={Array.isArray(unique) && unique.length ? unique.map((item) => ({ label: item.BranchID + ". " + item.BranchName + " Branch", value: item.BranchID })) : []}
                                defaultValue={{ label: "Select Dept", value: 0 }}
                                name="Division"
                                placeholder={"Branch"}
                                styles={CScolourStyles}
                                value={BranchFilter}
                                onChange={(e) => setBranchFilter(e)}
                                required
                                id="Title"
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "22%" }}>
                            <Select
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                borderRadius={"0px"}
                                // options={Data.map}
                                options={Array.isArray(unique_sup) && unique_sup.length ? unique_sup.map((item) => ({ label: item.SupplierTitle, value: item.SupplierID })) : []}
                                defaultValue={{ label: "Select Dept", value: 0 }}
                                name="Supplier"
                                placeholder={"Supplier"}
                                styles={CScolourStyles}
                                value={SupFilter}
                                onChange={(e) => setSupFilter(e)}
                                required
                                id="Supplier"
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "22%" }}>
                            <Select
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                borderRadius={"0px"}
                                // options={Data.map}
                                options={Array.isArray(unique_category) && unique_category.length ? unique_category.map((item) => ({ label: item.Category, value: item.Category })) : []}
                                defaultValue={{ label: "Select Dept", value: 0 }}
                                name="Category"
                                placeholder={"Category"}
                                styles={CScolourStyles}
                                value={CategoryFilter}
                                onChange={(e) => setCategoryFilter(e)}
                                required
                                id="Category"
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                        <div className="d-flex justify-content-end mx-2" style={{ minWidth: "22%" }}>
                            <Select
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                borderRadius={"0px"}
                                // options={Data.map}
                                options={Array.isArray(unique_search) && unique_search.length ? unique_search.map((item) => ({ label: item.ItemCode + ". " + item.Details, value: item.ItemCode })) : []}
                                defaultValue={{ label: "Select Dept", value: 0 }}
                                name="Search"
                                placeholder={"Product"}
                                styles={CScolourStyles}
                                value={SearchKey}
                                onChange={(e) => setSearchKey(e)}
                                required
                                id="Title"
                                isClearable={true}
                                isSearchable={true}
                                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            />
                        </div>

                    </div>
                </div>

                {Array.isArray(SaleList) && SaleList.length ?
                    <table className={`table table-hover table-borderless table-responsive card-1 d-table mt-1`}>
                        <thead>
                            <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Date</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Supplier</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Purchase No</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Category</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Products Details</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Qty</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Weight</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Rate</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Price</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Amount</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Payment</span></th>
                                <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Reciever</span></th>
                                {/* <th className="border-right p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase p-0">Branch</span></th> */}
                                <th className="p-1"> <span className="fs-6 fw-bolder text-dark text-uppercase"> Branch </span></th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                SaleList.map((item, i) => (
                                    <tr className="border-bottom text-center" key={i}>
                                        <td className="border-right p-1" style={{ whiteSpace: 'nowrap' }}><span className="d-block fs-6 fw-bold text-center text-dark p-0">{moment(item.Date).format('DD MMM YYYY')}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0">{item.SupplierTitle}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0">{item.PurchaseNo}</span> </td>

                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0">{item.Category}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-left text-dark p-0" style={{ whiteSpace: 'nowrap' }}>{item.Details}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{parseFloat(item.Qty).toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{parseFloat(item.Weight).toLocaleString("en", { minimumFractionDigits: 3 })}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{parseFloat(item.Rate).toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{parseFloat(item.Price).toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-right text-dark p-0">{parseFloat(item.GrandTotal).toLocaleString("en", { minimumFractionDigits: 2 })}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{parseInt(item.Payment) === 1 ? "Due" : parseInt(item.Payment) === 2 ? "Paid" : parseInt(item.Payment) === 3 ? "Partial" : "Contract"}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.Reciever}</span></td>
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bold text-center text-dark p-0">{item.BranchName}</span></td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <div className={`d-flex justify-content-center align-items-center bg-white`}>
                        <p className='fs-2 fw-bold text-center text-success m-0'>No Product Found!</p>
                    </div>
                }
            </div>
            {
                StockItem ?
                    <UpdateModal
                        Item={StockItem}
                        show={UpdateModalShow}
                        list={list}
                        setList={setList}
                        onReload={() => LoadDispatch()}
                        onHide={() => { setStockItem(false); setUpdateModalShow(false) }}
                    />

                    : null
            }
            <DeleteModal
                FullName={DeleteData ? DeleteData.FarmTitle + ", Invoice No-" + DeleteData.PurchaseNo : null}
                PurchaseNo={DeleteData ? DeleteData.PurchaseNo : null}
                show={DeleteData ? DeleteModalShow : false}
                DeleteClick={(e) => DeleteInvoice(e, DeleteData.id)}
                onHide={() => { setDeleteModalShow(false); setDeleteData(false) }}
            />
            {/* <InfoMessage
                header="Remove stock product!"
                body_header="Can not remove product"
                body="Product exist in physical store. So, you can not remove product without null stock"
                show={InfoModalShow}
                onHide={() => setInfoModalShow(false)}
            /> */}
        </div >
    );
}

const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(PurchaseReport);