import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div class="row pt-3 m-0">
            <div class="col-md-12 p-0">
                <div class="card card-body bg-transparent shadow-none m-0 py-0">
                    <div class="row align-items-end">
                        <div class="col-md-8">
                            <div class="row align-content-center justify-content-center">
                                <div class="col-md-4 m-1 border border-2 border-white" style={{ borderRadius: "15px" }}>
                                    <h6 className='text-center text-uppercase pt-2'><i class="fas fa-link px-2"></i>Our Social Media</h6>
                                    <div className='d-flex align-content-start flex-wrap justify-content-center'>
                                        <div class="social">
                                            <a href='https://www.facebook.com/softapoul' target="_blank"> <i class="fab fa-facebook-square text-dark"></i></a>
                                            <a href="#"><i class="fab fa-linkedin text-dark"></i></a>
                                            <a href="#"><i class="fab fa-instagram text-dark" aria-hidden="true"></i></a>
                                            <a href="#"><i class="fab fa-youtube text-dark" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-7 m-1 border border-2 border-white" style={{ borderRadius: "15px" }}>
                                    <img src={`${process.env.REACT_APP_API_URL}/Media/logo.png`} className="img-fluid mx-auto d-table p-2" width="200" height="40" alt="SoftaPoul" />
                                    <p className='text-center'><i class="fs-5 fad fa-map-marked-alt text-whatsapp px-2"></i>Angargara 2240, Bhaluka, Mymensingh, Dhaka, Bangladesh</p>
                                </div>
                            </div>
                        </div>



                        <div class="col-md-4 border border-light bg-gradient bg-white px-4 align-middle" style={{ borderRadius: "15px" }}>
                            <div className='d-flex align-content-center flex-wrap justify-content-center'>

                                <form class="form-footer my-3">
                                    <h6 className='text-center text-uppercase w-100'>Where you will get us?</h6>
                                    <p className='text-center text-uppercase text-muted'>Feel free to contact, we are ready!</p>
                                    <div className='justify-content-center'>
                                        <p className='fs-5 m-0 fw-bold'> <i class="fad fa-phone fa-flip-horizontal text-dark px-2"></i> 01633-00 81 39</p>
                                        <p className='fs-5 m-0 fw-bold'> <i class="fab fa-whatsapp fa-flip-horizontal text-dark px-2"></i> 01633-00 81 39</p>
                                        <p className='fs-5 m-0 fw-bold'> <i class="fad fa-envelope-open-text text-dark px-2"></i> info@softapoul.com</p>
                                        <Link className='fs-5 m-0 fw-bold text-dark' to={'/'}> <i class="fad fa-globe px-2"></i> www.softapoul.com</Link>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>


            </div>


            <div class="copyright mt-3 bg-light">
                <div class="container">
                    <div class="row d-flex ">
                        <span className='text-center fw-bold text-success'>Copyright © 2022, All Right Reserved</span>
                    </div>
                </div>
            </div>
            {/* <div id="back-to-top" class="back-to-top">
                <button onClick={() => window.scrollTo(0, 0)} class="btn btn-dark" title="Back to Top" style={{ display: "block" }}>
                    <i class="fa fa-angle-up"></i>
                </button>
            </div> */}
        </div >



    )
}

export default Footer;