import { DISPLAY_OVERLAY } from './types';
import axios from 'axios';
import { checkToken } from './auth';
import { useDispatch } from 'react-redux';

export const MarginOperation = [
    { label: "N/A", value: 0 },
    { label: "Contract Farm", value: 1 },
    { label: "Private Farm", value: 2 },
    { label: "Sub-dealers", value: 3 },
    { label: "Customers", value: 4 },
    { label: "Egg", value: 5 },
    { label: "Layer Farm", value: 6 },
    { label: "Party", value: 7 },
    { label: "Batch Payment", value: 8 },
    { label: "Net Payment", value: 9 },
    { label: "Grand Payment", value: 10 },
    { label: "Profit Margin (BS)", value: 11 },
    { label: "Market Retail Price (MRP)", value: 12 },
    { label: "Custom", value: 13 },
]

export const TransferMethodList = [
    { label: "Bank (EFTN)", value: 3 },
    { label: "Bank (RTGS)", value: 4 },
    { label: "Bank (IMPS)", value: 5 },
    { label: "Bank (UPI)", value: 6 },
    { label: "Bank (Cheque)", value: 7 },
    { label: "Bank (Pay Order)", value: 8 },

    { label: "MFSP (ROCKET)", value: 3 },
    { label: "MFSP (bKash)", value: 4 },
    { label: "MFSP (MYCash)", value: 5 },
    { label: "MFSP (mCash)", value: 6 },
    { label: "MFSP (tap)", value: 7 },
    { label: "MFSP (SureCash)", value: 8 },

    { label: "MFSP (upay)", value: 9 },
    { label: "MFSP (OK Wallet)", value: 10 },
    { label: "MFSP (TeleCash)", value: 11 }
    ,
    { label: "MFSP (Islamic Wallet)", value: 12 },
    { label: "MFSP (Tap `n Pay)", value: 13 },
    { label: "MFSP (Nagad)", value: 14 },
    { label: "Bank", value: 15 },
    { label: "MFSP", value: 16 },
]

export const findUnique = (arr, predicate) => {
    var found = {};
    arr.forEach(d => {
        found[predicate(d)] = d;
    });
    return Object.keys(found).map(key => found[key]);
}

export const LoadPending = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/pending_users/`, config);
        return res
    } catch (err) {
        return true
    }
}

export const LoadProfile = async (id) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile_view/${id}`, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const CreateAccount = async (CompanyID, BranchID, FirstName, LastName, email, MobileNo, password, con_pass, FullName, Nid_BirthNo, DOB, FatherName, MotherName, HoldingNo, WardNo, VillageName, Union, PostalCode, Upazila, Zila, Division, Nationality, Religion, Gender, Occupation, EducationalQualification, Image, RefID, RepID, NIDCopy, Agreement, BankCheque, FarmRegCopy, is_block, is_active, is_staff, is_subscriber, ImagePrv) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("email", email);
    formData.append("MobileNo", MobileNo);
    formData.append("password", password);
    formData.append("con_pass", con_pass);
    formData.append("FullName", FullName);
    formData.append("Nid_BirthNo", Nid_BirthNo);
    formData.append("DOB", DOB);
    formData.append("FatherName", FatherName);
    formData.append("MotherName", MotherName);
    formData.append("HoldingNo", HoldingNo);
    formData.append("WardNo", WardNo);
    formData.append("VillageName", VillageName);
    formData.append("Union", Union);
    formData.append("PostalCode", PostalCode);
    formData.append("Upazila", Upazila);
    formData.append("Zila", Zila);
    formData.append("Division", Division);
    formData.append("Nationality", Nationality);
    formData.append("Religion", Religion);
    formData.append("Gender", Gender);
    formData.append("Occupation", Occupation);
    formData.append("EducationalQualification", EducationalQualification);
    if (ImagePrv) { formData.append("Image", Image, Image.name); }
    formData.append("RefID", RefID);
    formData.append("RepID", RepID);
    formData.append("NIDCopy", NIDCopy);
    formData.append("Agreement", Agreement);
    formData.append("BankCheque", BankCheque);
    formData.append("FarmRegCopy", FarmRegCopy);

    formData.append("is_block", is_block ? 1 : 0);
    formData.append("is_active", is_active ? 1 : 0);
    formData.append("is_staff", is_staff ? is_staff : 0);
    formData.append("is_subscriber", is_subscriber ? 1 : 0);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/profile_action/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const GetCompany = async (id) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_company/${id}`, config);
        return res
    } catch (err) {
        return true
    }
}

export const GetBranch = async (id) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_branch/${id}`, config);
        return res
    } catch (err) {
        return true
    }
}

export const SaveBranch = async (CompanyID, BranchID, Name, Domain, Established, ManagerID, ContactNo, BranchEmail, Division, Zila, Upazila, Union, VillageName) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("Name", Name);
    formData.append("Domain", Domain);
    formData.append("Established", Established);
    formData.append("ManagerID", ManagerID);
    formData.append("ContactNo", ContactNo);
    formData.append("BranchEmail", BranchEmail);
    formData.append("VillageName", VillageName);
    formData.append("Union", Union);
    formData.append("Upazila", Upazila);
    formData.append("Zila", Zila);
    formData.append("Division", Division);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/branch_action/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const BranchUpdate = async (BranchID, Name, Domain, Established, ManagerID, ContactNo, BranchEmail, Division, Zila, Upazila, Union, VillageName) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BranchID", BranchID);
    formData.append("Name", Name);
    formData.append("Domain", Domain);
    formData.append("Established", Established);
    formData.append("ManagerID", ManagerID);
    formData.append("ContactNo", ContactNo);
    formData.append("BranchEmail", BranchEmail);
    formData.append("VillageName", VillageName);
    formData.append("Union", Union);
    formData.append("Upazila", Upazila);
    formData.append("Zila", Zila);
    formData.append("Division", Division);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/branch_action/${BranchID}/`, formData, config);
        return res.data
    } catch (err) {
        return err
    }
}

export const ApproveData = async (BranchID, id, FirstName, LastName, email, MobileNo, FullName, Nid_BirthNo, DOB, FatherName, MotherName, HoldingNo, WardNo, VillageName, Union, PostalCode, Upzila, Zila, Division, Nationality, Religion, Gender, Occupation, EducationalQualification, Image, RefID, RepID, NIDCopy, Agreement, BankCheque, FarmRegCopy, is_block, is_active, is_staff, is_subscriber, ImagePrv) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BranchID", BranchID);
    formData.append("UserID", id);
    formData.append("FirstName", FirstName);
    formData.append("LastName", LastName);
    formData.append("email", email);
    formData.append("MobileNo", MobileNo);
    formData.append("FullName", FullName);
    formData.append("Nid_BirthNo", Nid_BirthNo);
    formData.append("DOB", DOB);
    formData.append("FatherName", FatherName);
    formData.append("MotherName", MotherName);
    formData.append("HoldingNo", HoldingNo);
    formData.append("WardNo", WardNo);
    formData.append("VillageName", VillageName);
    formData.append("Union", Union);
    formData.append("PostalCode", PostalCode);
    formData.append("Upazila", Upzila);
    formData.append("Zila", Zila);
    formData.append("Division", Division);
    formData.append("Nationality", Nationality);
    formData.append("Religion", Religion);
    formData.append("Gender", Gender);
    formData.append("Occupation", Occupation);
    formData.append("EducationalQualification", EducationalQualification);
    if (ImagePrv) {
        formData.append("Image", Image, Image.name);
    }
    formData.append("RefID", RefID);
    formData.append("RepID", RepID);
    formData.append("NIDCopy", NIDCopy);
    formData.append("Agreement", Agreement);
    formData.append("BankCheque", BankCheque);
    formData.append("FarmRegCopy", FarmRegCopy);
    formData.append("is_block", is_block);
    formData.append("is_active", is_active);
    formData.append("is_staff", is_staff);
    formData.append("is_subscriber", is_subscriber);
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/profile_action/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return err
    }
}

export const UpdateCredential = async (id, email, NewPass, MobileNo) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("UserID", id);
    formData.append("email", email);
    formData.append("password", NewPass);
    formData.append("MobileNo", MobileNo);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_new_pass/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return err
    }
}

export const ResetPass = async (id, old_pass, new_pass, con_pass) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("id", id);
    formData.append("old_pass", old_pass);
    formData.append("new_pass", new_pass);
    formData.append("con_pass", con_pass);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/change_pass/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return err
    }
}

export const UpdateImage = async (id, Image) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("id", id);
    formData.append("Image", Image, Image.name);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_image/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}


export const UpdateCMPLogo = async (id, Image) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("id", id);
    formData.append("Logo", Image, Image.name);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/update_sup_logo/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const RemoveUser = async (id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/profile_action/${id}/`, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const Designation = [
    { label: "Office Clerk", value: 1 },
    { label: "Sales Executive", value: 2 },
    { label: "Store Keeper", value: 3 },
    { label: "Field Worker", value: 4 },
    { label: "Sales Manager", value: 5 },
    { label: "Area Manager", value: 6 },
    { label: "Branch Manager", value: 7 },

    { label: "Cashier", value: 8 }
]

export const Designation_CEO = [
    { label: "Office Clerk", value: 1 },
    { label: "Sales Executive", value: 2 },
    { label: "Store Keeper", value: 3 },
    { label: "Field Worker", value: 4 },

    { label: "Sales Manager", value: 5 },
    { label: "Area Manager", value: 6 },
    { label: "Branch Manager", value: 7 },

    { label: "Cashier", value: 8 },
    { label: "Accounts", value: 9 },
    { label: "Admin", value: 10 },
]

export const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "#F4F7FC",
        border: 0,
        boxShadow: "none",
        fontWeight: "bold",
        minHeight: "fit-content",
        height: "25px",
        borderRadius: "0px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            cursor: isDisabled ? "not-allowed" : "default",
            borderRadius: "20px",
        };
    },
    menu: (base) => ({
        ...base,
        borderRadius: "0px",
        outline: 0,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({
        ...base,
        padding: "5px",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: "25px",
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: "25px",
        padding: "0 3px",
        color: "black",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "black",
    }),
};


export const LoadBanks = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/bank_lists/`, config);
        return res.data
    } catch (err) {
    }
}

export const getBranch = async (bank_name) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/branch_name/${bank_name}`, config);
        return res
    } catch (err) {
    }
}

export const SaveBank = async (AccName, AccNumber, BankID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("UserID", "");
    formData.append("AccName", AccName ? AccName : "");
    formData.append("AccNumber", AccNumber ? AccNumber : "");
    formData.append("BankID", BankID);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user_bank/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const BankUpdate = async (CompanyID, BranchID, UserID, AccName, AccNumber, BankID, UserBankID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("UserID", UserID);
    formData.append("AccName", AccName ? AccName : "");
    formData.append("AccNumber", AccNumber ? AccNumber : "");
    formData.append("BankID", BankID);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/user_bank/${UserBankID}/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const RemoveBank = async (BankID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/user_bank/${BankID}/`, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const SaveCMPBank = async (AccName, AccNumber, BankID, Status) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("AccName", AccName ? AccName : "");
    formData.append("AccNumber", AccNumber ? AccNumber : "");
    formData.append("BankID", BankID);
    formData.append("Status", Status);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/cmp_bank/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const BankCMPUpdate = async (AccName, AccNumber, BankID, Status, id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("AccName", AccName ? AccName : "");
    formData.append("AccNumber", AccNumber ? AccNumber : "");
    formData.append("BankID", BankID);
    formData.append("Status", Status);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/cmp_bank/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const RemoveCMPBank = async (BankID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/user_bank/${BankID}/`, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const LoadMyUsers = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_users/`, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const UserProfile = async (id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/user_pro/${id}`, config);
        return res

    } catch (err) {
        return true;
    }
}

export const AllProductList = async (SupplierID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/sup_product_list/${SupplierID}`, config);
        return res
    } catch (err) {
        return true;
    }
}

export const LoadAccounts = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/`, config);
        return res.data
    } catch (err) {
    }
}

export const SaveAcc = async (ParentID, COA_ID, COA_Title, COA_Code, TransType, AccType, Terms, GrossProfit, MoneyType, TreeLevel) => {
    // const dispatch = useDispatch();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("ParentID", ParentID);
    formData.append("COA_ID", COA_ID);
    formData.append("COA_Title", COA_Title);
    formData.append("COA_Code", COA_Code);
    formData.append("TransType", TransType);
    formData.append("AccType", AccType);
    formData.append("Terms", Terms);
    formData.append("GrossProfit", GrossProfit);
    formData.append("MoneyType", MoneyType);
    formData.append("TreeLevel", TreeLevel);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/accounts/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const UpdateAcc = async (ParentID, COA_ID, COA_Title, COA_Code, TransType, AccType, Terms, GrossProfit, MoneyType, TreeLevel, id) => {
    // const dispatch = useDispatch();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("ParentID", ParentID);
    formData.append("COA_ID", COA_ID);
    formData.append("COA_Title", COA_Title);
    formData.append("COA_Code", COA_Code);
    formData.append("TransType", TransType);
    formData.append("AccType", AccType);
    formData.append("Terms", Terms);
    formData.append("GrossProfit", GrossProfit);
    formData.append("MoneyType", MoneyType);
    formData.append("TreeLevel", TreeLevel);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/accounts/${id}/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const RemoveAcc = async (id) => {



    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/accounts/${id}/`, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const LoadAccount = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_acc/`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const LoadMainAcc = async (id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_acc/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const VoucherSave = async (VoucherType, Date, VoucherNo, PaymentMethod, Name, Narration, Count, AccData) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("VoucherType", VoucherType.value);
    formData.append("Date", Date);
    formData.append("VoucherNo", VoucherNo);
    formData.append("PaymentMethod", PaymentMethod);
    formData.append("Consignee", Name);
    formData.append("Narration", Narration);
    formData.append("Count", Count);

    Object.keys(AccData).map(e => {
        formData.append(`VMapData[${e}]SLNo`, AccData[e].SLNo);
        formData.append(`VMapData[${e}]SupplierID`, AccData[e].SupplierID === undefined ? "" : AccData[e].SupplierID);
        formData.append(`VMapData[${e}]UserID`, AccData[e].UserID === undefined ? "" : AccData[e].UserID);
        formData.append(`VMapData[${e}]BusinessID`, AccData[e].BusinessID === undefined ? "" : AccData[e].BusinessID);
        formData.append(`VMapData[${e}]BatchID`, AccData[e].BatchID === undefined ? "" : AccData[e].BatchID);
        formData.append(`VMapData[${e}]COA_Code`, AccData[e].COA_Code);
        formData.append(`VMapData[${e}]COA`, AccData[e].COA_ID);
        formData.append(`VMapData[${e}]DR`, AccData[e].Debit);
        formData.append(`VMapData[${e}]CR`, AccData[e].Credit);
        formData.append(`VMapData[${e}]SenderBank`, AccData[e].SenderBank === undefined ? "" : AccData[e].SenderBank);
        formData.append(`VMapData[${e}]AccName`, AccData[e].AccName === undefined ? "" : AccData[e].AccName);
        formData.append(`VMapData[${e}]AccNumber`, AccData[e].AccNumber === undefined ? "" : AccData[e].AccNumber);
        formData.append(`VMapData[${e}]ChequeNo`, AccData[e].ChequeNo === undefined ? "" : AccData[e].ChequeNo);
        formData.append(`VMapData[${e}]BankID`, AccData[e].BankID === undefined ? "" : AccData[e].BankID);
    });

    for (var pair of formData.entries()) {
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/voucher/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const AccJournal = async (Date) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_journal/${Date}`, config);
        return res.data
    } catch (err) {
    }
}

export const DeleteJournal = async (id) => {



    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/voucher/${id}/`, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const LoadBranch = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/branch_acc/`, config);
        return res.data
    } catch (err) {
    }
}

export const BranchAcc = async (Cash, Sell, Purchase, Payable, Receivable, BatchSell, ProductSent, Stock, BatchPayment, BatchPaymentPayable, SavingPayment, SavingPaymentPayable, BranchID) => {
    // const dispatch = useDispatch();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CashAC", Cash);
    formData.append("SellAC", Sell);
    formData.append("PurchaseAC", Purchase);
    formData.append("PayableAC", Payable);
    formData.append("ReceivableAC", Receivable);
    formData.append("BatchSellAC", BatchSell);
    formData.append("ProductSentAC", ProductSent);
    formData.append("StockAC", Stock);
    formData.append("BatchPaymentAC", BatchPayment);
    formData.append("BatchPaymentPayableAC", BatchPaymentPayable);
    formData.append("SavingPaymentAC", SavingPayment);
    formData.append("SavingPaymentPayableAC", SavingPaymentPayable);
    formData.append("BranchID", BranchID);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/branch_acc/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchProfitMargin = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/profit_margin/`, config);
        return res.data
    } catch (err) {
    }
}

export const SaveMargin = async (Title, ProfitMargin, Currency, Operation, Status) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Title", Title);
    formData.append("ProfitMargin", ProfitMargin);
    formData.append("Currency", Currency);
    formData.append("Operation", Operation);
    formData.append("Status", Status);

    for (var pair of formData.entries()) {

    }
    try {

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/profit_margin/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const UpdateProfitMargin = async (Title, ProfitMargin, Currency, Operation, Status, id) => {
    // const dispatch = useDispatch();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Title", Title);
    formData.append("ProfitMargin", ProfitMargin);
    formData.append("Currency", Currency);
    formData.append("Operation", Operation);
    formData.append("Status", Status);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/profit_margin/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const DeleteProfitMargin = async (id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/profit_margin/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}


export const FetchMargin = async (Operation) => {


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/margin_list/${Operation}`, config);


        return res.data
    } catch (err) {
        return true;
    }
}


export const SaveBisBank = async (UserID, BisID, AccName, AccNumber, BankID, Status) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("UserID", UserID);
    formData.append("BusinessID", BisID);
    formData.append("AccName", AccName);
    formData.append("AccNumber", AccNumber);
    formData.append("BankID", BankID);
    formData.append("Status", Status);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/usr_bis_bank/`, formData, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const RemoveBisBank = async (BankID) => {



    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/usr_bis_bank/${BankID}/`, config);
        return res.data
    } catch (err) {
        return true
    }
}

export const FetchBisBank = async (BisID) => {


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/load_usr_bis_bank/${BisID}`, config);


        return res.data
    } catch (err) {
        return true;
    }
}

export const EnableBank = async (id, Status) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Status", Status ? 0 : 1);
    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/usr_bis_bank/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchInvoiceNo = async (InvoiceType) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_invoice_no/${InvoiceType}`, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const FetchVoucherNo = async (VoucherType) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_voucher_no/${VoucherType}`, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const Invoice = async (BisID, InvoiceNo, Date, VatRate, Vat, Discount, Payment, GrandTotal, Paid, Due, Count, SellData, Sell_ID, Sell_Code) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BusinessID", BisID);
    formData.append("InvoiceNo", InvoiceNo);
    formData.append("Date", Date);
    formData.append("VatRate", VatRate);
    formData.append("Vat", Vat.toFixed(2));
    formData.append("Discount", Discount.toFixed(2));
    formData.append("Payment", Payment);
    formData.append("GrandTotal", GrandTotal.toFixed(2));
    formData.append("PaidAmount", Paid.toFixed(2));
    formData.append("Due", Due.toFixed(2));
    formData.append("ItemCount", Count);
    formData.append(`COA`, Sell_ID);
    formData.append(`COA_Code`, Sell_Code);

    Object.keys(SellData).map(e => {
        formData.append(`SellMapData[${e}]InvoiceNo`, InvoiceNo);
        formData.append(`SellMapData[${e}]SLNo`, SellData[e].SLNo);
        formData.append(`SellMapData[${e}]ItemCode`, SellData[e].ItemCode);
        formData.append(`SellMapData[${e}]UnitWeight`, SellData[e].UnitWeight);
        formData.append(`SellMapData[${e}]UnitPrice`, parseFloat(SellData[e].UnitPrice).toFixed(2));
        formData.append(`SellMapData[${e}]Weight`, parseFloat(SellData[e].Weight).toFixed(3));
        formData.append(`SellMapData[${e}]Qty`, parseFloat(SellData[e].Quantity).toFixed(2));
        formData.append(`SellMapData[${e}]Rate`, parseFloat(SellData[e].Rate).toFixed(2));
        formData.append(`SellMapData[${e}]SubTotal`, parseFloat(SellData[e].SubTotal).toFixed(2));
        formData.append(`SellMapData[${e}]Status`, 1);
        formData.append(`SellMapData[${e}]COA`, Sell_ID);
        formData.append(`SellMapData[${e}]COA_Code`, Sell_Code);
    });


    try {
        for (var pair of formData.entries()) {
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/sale/`, formData, config);

        return res.data
    } catch (err) {


        return true;
    }
}


export const BusinessType = async (Type) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/business_type_load/${Type}`, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchBisLadger = async (BusinessID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/business_ladger/${BusinessID}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const FetchSupplierLadger = async (SupplierID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/supplier_ladger/${SupplierID}`, config);

        return res
    } catch (err) {
        return true;
    }
}

export const FetchCashFlow = async (COA_ID, Date) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/cash_flow/${COA_ID}/${Date}`, config);
        return res
    } catch (err) {
        return true;
    }
}

export const FetchBranch = async () => {


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/branch_list/`, config);
        return res.data
    } catch (err) {
    }
}


export const LoadParty = async () => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_party/`, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const SaveParty = async (Title, Name, Address, Contact, SCMoney, Limit, Balance, Target, Currency, FarmReg, BlankCheque, Agreement, Status, RepID) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Title", Title);
    formData.append("Name", Name);
    formData.append("Address", Address);
    formData.append("Contact", Contact);
    formData.append("SCMoney", SCMoney);
    formData.append("Limit", Limit);
    formData.append("Balance", Balance);
    formData.append("Target", Target);
    formData.append("Currency", Currency);
    formData.append("FarmReg", FarmReg);
    formData.append("BlankCheque", BlankCheque);
    formData.append("Agreement", Agreement);
    formData.append("Status", 1);
    formData.append("RepID", RepID);


    try {
        for (var pair of formData.entries()) {
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/my_party/`, formData, config);
        return res.data
    } catch (err) {
        return true;
    }
}


export const GetParty = async (id) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_party/${id}`, config);
        return res.data[0]
    } catch (err) {
        return true;
    }
}


export const UpdateParty = async (id, Title, Name, Contact, SCMoney, Limit, Balance, Target, Currency, RepID, FarmReg, BlankCheque, Agreement) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("Title", Title);
    formData.append("Name", Name);
    formData.append("Contact", Contact);
    formData.append("SCMoney", SCMoney);
    formData.append("Limit", Limit);
    formData.append("Balance", Balance);
    formData.append("Target", Target);
    formData.append("Currency", Currency);
    formData.append("RepID", parseInt(RepID));
    formData.append("FarmReg", FarmReg ? 1 : 0);
    formData.append("BlankCheque", BlankCheque ? 1 : 0);
    formData.append("Agreement", Agreement ? 1 : 0);

    try {
        for (var pair of formData.entries()) {
        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/my_party/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}


export const FetchBankAcc = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_cm_bank_ac/`, config);
        return res.data
    } catch (err) {
        return true;
    }
}

export const RunningBalance = async (Date) => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/balance/${Date}`, config);
        return res.data
    } catch (err) {
        return true;
    }
}