import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

const Wellcome = ({ data, login, error, message, level, sub_level }) => {
    // if (level === 6 || (level === 3 && (sub_level === 1 || sub_level === 2 || sub_level === 3 || sub_level === 5 || sub_level === 6 || sub_level === 7 || sub_level === 9 || sub_level === 10) && sub_level !== 4)) {
    //     return <Redirect to='/wellcome' />
    // } else if (level === 3 && sub_level === 4) {
    //     return <Redirect to='/field_work' />
    // } else if (level === null || sub_level === null) {
    //     return <Redirect to='/home' />
    // }

    if (level === 3 && sub_level === 4)
        return <Redirect to='/field_work' />

    return (
        <div className="row h-100 m-0 d-flex justify-content-center">
            <div className="header mb-4 bg-gradient maintxt align-items-center justify-content-center" style={{ maxHeight: "25vh", textShadow: "2px 1px 0px rgba(0, 0, 0, 0.1)" }}>
                {data ?
                    <div className='row align-items-center justify-content-center'>
                        <p className="display-2 d-flex justify-content-center text-success fw-bold m-0" style={{ fontFamily: "MyriadPro_bold" }}>{data.CompanyName}</p>
                        <p className="blockquote-footer fs-2 d-flex justify-content-center text-success fw-normal" style={{ fontFamily: "Tahoma" }}>{data.BranchName + " Branch"}</p>

                        <p className="fs-4 text-center justify-content-center fw-normal border border-3 border-white w-50 m-0 p-3" style={{ borderRadius: "15px" }}>Welcome back &nbsp;<p className="fw-bold m-0">{data.FullName + "!"}</p></p>
                    </div>
                    : null
                }
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    data: state.auth.user,
    display: state.OverlayDisplay,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error,
    message: state.auth.message,
    level: state.auth.level,
    sub_level: state.auth.sub_level,
});
export default connect(mapStateToProps)(Wellcome);
