import { Modal} from "react-bootstrap";
import React, { useEffect, useState} from 'react';
import Select from 'react-select';
import { checkToken } from '../../../../actions/auth';
import { AllMyProductList, ProductInit, UpdateStock } from '../../../../actions/InventoryAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import errorIcon from '../../../../assets/error.png';
import infoIcon from '../../../../assets/info.png';
import successIcon from '../../../../assets/success.png';
import warningIcon from '../../../../assets/warning.gif';
import { FetchBranch } from "../../../../actions/APIHandler";

export const InitProductModal = (props) => {
    const account = useSelector((state) => state.auth.accounts);

    const initialValue = { value: 0, label: "" };
    const [Error, setError] = useState({});
    const [BranchList, setBranchList] = useState();
    const [Branch, setBranch] = useState();
    const [MyProList, setMyProList] = useState();
    const [formData, setFormData] = useState({
        BarCode: "",
        ItemCode: "",
        Title: "",
        Category: "",
        UnitWeight: "",
        Weight: "",
        UnitPrice: "",
        Quantity: "",
        SubTotal: "",
        MinRequired: "",
        Status: 1,
        COA: account.StockAC_ID,
        COA_Code: account.StockAC_Code
    });
    const { ItemCode, Category, Title, UnitWeight, UnitPrice, Quantity, Weight, SubTotal, MinRequired, Status, COA, COA_Code } = formData;
    let toastProperties = null;
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        LoadBranch();
        LoadProductItems();
    }, [])

    const PropLoadSet = () => {
        setFormData({
            BarCode: "", ItemCode: "", Title: "", Category: "", UnitWeight: "", Weight: "", UnitPrice: "", Quantity: "", SubTotal: "", MinRequired: "", Status: "",
        });
        setError(null);
        props.onHide();
    }

    const ProductInitialize = async (e) => {
        e.preventDefault();
        if (Branch) {
            const result = await ProductInit(Branch.value, ItemCode, UnitPrice, Quantity, parseFloat(Weight).toFixed(3), MinRequired, Status, COA, COA_Code);
            if (result !== true) {
                if (result.user_error) {
                    const updatedState = {};
                    for (var pair of result.exception.entries()) {
                        updatedState[pair[1].field] = pair[1].message;
                        setError({
                            ...updatedState,
                        });
                    }
                    props.setList([...props.list, toastProperties = {
                        id: 1,
                        title: result.Title,
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: warningIcon
                    }])

                } else {
                    props.setList([...props.list, toastProperties = {
                        id: 1,
                        title: result.Title,
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: successIcon
                    }])
                }
            } else {
                props.setList([...props.list, toastProperties = {
                    id: 1,
                    title: 'Error',
                    description: "Product Initialize failed. Please try after some moment.",
                    backgroundColor: '#f0ad4e',
                    icon: errorIcon
                }])
            }
        }
    }

    const QuantityCalc = (e) => {
        let weight = UnitWeight * e.target.value
        let price = UnitPrice * e.target.value
        setFormData({ ...formData, "Weight": weight, "SubTotal": price, "Quantity": e.target.value })
    }

    const LoadBranch = async () => {
        var result = await FetchBranch();
        if (result !== true) {
            setBranchList(result.Branch);
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
        } else {
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
            history.push('/');
        }
    }

    const LoadProductItems = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var ProductItems = await AllMyProductList();
        if (ProductItems !== true)
            setMyProList(ProductItems.data);
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: "#F4F7FC", border: 0, boxShadow: 'none', fontWeight: "bold", minHeight: "fit-content", height: "25px", borderRadius: '0px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                borderRadius: '20px',
            };
        },
        menu: base => ({
            ...base,
            borderRadius: '0px',
            outline: 0,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menuList: (base) => ({
            ...base,
            padding: '5px'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '25px',
            padding: '0 3px',
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        })
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static">

            <Modal.Body>
                <div className="d-flex flex-row-reverse bd-highlight">
                    <button className="btn-close fs-5" aria-label="Close" Title="Close" onClick={() => PropLoadSet()} />
                </div>
                <div className="d-flex justify-content-center bg-white h-100">
                    <div className="row justify-content-center align-items-center">
                        <span className="fs-4 fw-bolder text-center px-0 text-uppercase">Initialize Product Stock</span>
                        <small className="text-center px-0 text-muted">(Please fill up the desired field)</small>
                        <form>
                            <div className="row mt-3">
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <Select menuPortalTarget={document.body}
                                            borderRadius={'0px'}
                                            options={BranchList}
                                            name='Branch'
                                            placeholder={"Select branch"}
                                            styles={colourStyles}
                                            value={Branch}
                                            onChange={e => setBranch(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <Select menuPortalTarget={document.body}
                                            borderRadius={'0px'}
                                            options={Array.isArray(MyProList) && MyProList.length ? MyProList.map((item) => ({
                                                ItemCode: item.ItemCode,
                                                value: item.ItemCode,
                                                Category: item.Category,
                                                label: item.Title,
                                                UnitWeight: item.UnitWeight,
                                                UnitPrice: item.UnitPrice,
                                                Weight: 0,
                                                Quantity: 0,
                                                SubTotal: 0,
                                                Status: 1,
                                                MinRequired: 5,
                                                COA: account.StockAC_ID,
                                                COA_Code: account.StockAC_Code
                                            })) : []}
                                            name='Branch'
                                            placeholder={"Select product"}
                                            styles={colourStyles}
                                            value={Title}
                                            onChange={e => setFormData(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label text-center">Item Code</label>
                                        <input
                                            type="text"
                                            className="form-control fw-bold"
                                            id="ItemCode"
                                            name="ItemCode"
                                            placeholder='Item Code'
                                            value={formData ? ItemCode : ""}
                                            disabled
                                        />
                                        {Error.ItemCode ?
                                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.ItemCode}</small></p>
                                            : null}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Unit Weight</label>
                                        <input
                                            type="text"
                                            className="form-control fw-bold"
                                            id="UnitWeight"
                                            name="UnitWeight"
                                            placeholder='Unit Weight'
                                            value={UnitWeight}
                                            disabled
                                        />
                                        {Error.UnitWeight ?
                                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.UnitWeight}</small></p>
                                            : null}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Unit Price</label>
                                        <input
                                            type="text"
                                            className="form-control fw-bold"
                                            id="UnitPrice"
                                            name="UnitPrice"
                                            placeholder='Unit Price'
                                            value={UnitPrice ? UnitPrice.toLocaleString("en-BD", { minimumFractionDigits: 2 }) : null}
                                            onChange={(e) => setFormData({ ...formData, "UnitPrice": e.target.value })}
                                        />
                                        {Error.UnitPrice ?
                                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.UnitPrice}</small></p>
                                            : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Quantity</label>
                                        <input
                                            type="text"
                                            className="form-control fw-bold"
                                            id="Quantity"
                                            name="Quantity"
                                            placeholder='Quantity'
                                            value={Quantity.toLocaleString("en-BD", { minimumFractionDigits: 0 })}
                                            onChange={(e) => QuantityCalc(e)}
                                            onBlur={(e) => QuantityCalc(e)}
                                        />
                                        {Error.Quantity ?
                                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Quantity}</small></p>
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Weight</label>
                                        <input
                                            type="text"
                                            className="form-control fw-bold"
                                            id="Weight"
                                            name="Weight"
                                            placeholder='Total Weight'
                                            value={Weight ? Weight.toLocaleString("en-BD", { minimumFractionDigits: 3 }) : null}
                                            disabled
                                        />
                                        {Error.Weight ?
                                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Weight}</small></p>
                                            : null}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">SubTotal</label>
                                        <input
                                            type="text"
                                            className="form-control fw-bold"
                                            id="SubTotal"
                                            name="SubTotal"
                                            placeholder='Sub Total'
                                            value={SubTotal ? SubTotal.toLocaleString("en-BD", { minimumFractionDigits: 2 }) : null}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label for="message-text" className="col-form-label">Minimum Quantity</label>
                                <input
                                    type="text"
                                    className="form-control fw-bold"
                                    id="MinRequired"
                                    name="MinRequired"
                                    placeholder='Minimum Quantity'
                                    value={MinRequired}
                                    onChange={(e) => setFormData({ ...formData, "MinRequired": e.target.value })}
                                />
                                {Error.MinRequired ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.MinRequired}</small></p>
                                    : null}
                            </div>

                            <div className="form-group">
                                <label for="message-text" className="col-form-label">Active Status</label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={Status}
                                        id="Status"
                                        name="Status"
                                        checked={Status}
                                        onChange={(e) => setFormData({ ...formData, "Status": Status === 1 ? 0 : 1 })}
                                    />
                                    <label className="form-check-label text-center fw-bold pr-2" for={Status}>{Status === 1 ? "Active" : "Deactive"}</label>
                                    {Error.Status ?
                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Status}</small></p>
                                        : null}
                                </div>
                            </div>


                        </form>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-success" onClick={() => PropLoadSet()}><i className="fad fa-times pr-2"></i> Close</button>
                            <button className="btn btn-outline-success fs-6 fw-bold text-center mx-2" onClick={(e) => ProductInitialize(e)}>
                                <i className="fad fa-edit pr-2"></i> Submit </button>
                        </div>
                    </div>
                </div>
            </Modal.Body >
        </Modal >
    );
}


export const UpdateModal = (props) => {
    const [Qty, setQty] = useState(props.Item.Qty ? props.Item.Qty : null)
    const [Weight, setWeight] = useState(props.Item.Weight ? props.Item.Weight : null)
    const [Cost, setCost] = useState(props.Item.Cost ? props.Item.Cost : false)
    const [MinRequired, setMinRequired] = useState(props.Item.MinRequired ? props.Item.MinRequired : false)
    const [InitStock, setInitStock] = useState(props.Item.InitStock ? props.Item.InitStock : false)
    const [Status, setStatus] = useState(props.Item.Status ? props.Item.Status : false)
    const [Error, setError] = useState({});

    let toastProperties = null;
    const dispatch = useDispatch();

    const PropLoadSet = () => {
        setQty(false);
        setWeight(false);
        setCost(false);
        setMinRequired(false);
        setInitStock(false);
        setStatus(false);
        props.onHide();
    }

    const StockUpdate = async () => {
        const result = await UpdateStock(props.Item.id, Qty, Weight, Cost, MinRequired, InitStock, Status);

        if (result !== true) {
            if (result.user_error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                props.setList([...props.list, toastProperties = {
                    id: 1,
                    title: 'Invalid props.item',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }])

            } else {
                props.setList([...props.list, toastProperties = {
                    id: 1,
                    title: 'Success',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                props.onReload();
                props.onHide();
            }
        } else {
            props.setList([...props.list, toastProperties = {
                id: 1,
                title: 'Error',
                description: "Stock update failed. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
        }
    }

    const QuantityCalc = (e) => {
        let weight = props.Item.ItemCode.UnitWeight * e.target.value
        setWeight(weight)
        setQty(e.target.value)
    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static">

            <Modal.Body>
                <div className="d-flex flex-row-reverse bd-highlight">
                    <button className="btn-close fs-5" aria-label="Close" Title="Close" onClick={() => PropLoadSet()} />
                </div>
                <div className="d-flex justify-content-center bg-white h-100">
                    <div className="row justify-content-center align-items-center">
                        <span className="fs-4 fw-bolder text-center px-0 text-uppercase">Update Stock</span>
                        <small className="fs-5 fw-bold text-center px-0">{props.Item.ItemCode.Title}</small>
                        <small className="text-center px-0">(Please fill up the desired field to update)</small>
                        <form>
                            <div className="form-group">
                                <label for="message-text" class="col-form-label">Unit Price</label>
                                <input
                                    type="text"
                                    class="form-control fw-bold"
                                    id="Cost"
                                    name="Cost"
                                    placeholder='Unit Price'
                                    value={Cost}
                                    onChange={(e) => setCost(e.target.value)}
                                />
                                {Error.Cost ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Cost}</small></p>
                                    : null}
                            </div>

                            <div className="form-group">
                                <label for="message-text" class="col-form-label">Quantity</label>
                                <input
                                    type="text"
                                    class="form-control fw-bold"
                                    id="Qty"
                                    name="Qty"
                                    placeholder='Quantity'
                                    value={Qty}
                                    onChange={(e) => QuantityCalc(e)}
                                />
                                {Error.Qty ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Qty}</small></p>
                                    : null}
                            </div>

                            <div className="form-group">
                                <label for="message-text" class="col-form-label">Weight</label>
                                <input
                                    type="text"
                                    class="form-control fw-bold"
                                    id="Weight"
                                    name="Weight"
                                    placeholder='Unit Price'
                                    value={Weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                    disabled
                                />
                                {Error.Weight ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Weight}</small></p>
                                    : null}
                            </div>

                            <div className="form-group">
                                <label for="message-text" class="col-form-label">Minimum Quantity</label>
                                <input
                                    type="text"
                                    class="form-control fw-bold"
                                    id="MinRequired"
                                    name="MinRequired"
                                    placeholder='Minimum Quantity'
                                    value={MinRequired}
                                    onChange={(e) => setMinRequired(e.target.value)}
                                />
                                {Error.MinRequired ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.MinRequired}</small></p>
                                    : null}
                            </div>

                            <div className="form-group">
                                <label for="message-text" class="col-form-label">Initial Stock</label>
                                <input
                                    type="text"
                                    class="form-control fw-bold"
                                    id="InitStock"
                                    name="InitStock"
                                    placeholder='Initial Stock'
                                    value={InitStock}
                                    onChange={(e) => setInitStock(e.target.value)}
                                />
                                {Error.InitStock ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.InitStock}</small></p>
                                    : null}
                            </div>

                            <div className="form-group">
                                <label for="message-text" class="col-form-label">Active Status</label>
                                <div className="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value={Status}
                                        id="Status"
                                        name="Status"
                                        checked={Status}
                                        onChange={(e) => setStatus(!Status ? true : false)}
                                    />
                                    <label class="form-check-label text-center fw-bold pr-2" for={Status}>{Status === true ? "Active" : "Deactive"}</label>
                                    {Error.Status ?
                                        <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Status}</small></p>
                                        : null}
                                </div>
                            </div>


                        </form>
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-outline-success" onClick={() => PropLoadSet()}><i class="fad fa-times pr-2"></i> Close</button>
                            <button className="btn btn-outline-success fs-6 fw-bold text-center mx-2" onClick={() => StockUpdate()}>
                                <i class="fad fa-edit pr-2"></i> Update </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}

export const DeleteModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{props.FullName}</h4>
                <p>
                    Do you want to delete <strong>{props.FullName}</strong>?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={props.Click}>
                    Delete
                </button>
                <button className="btn btn-outline-success" onClick={props.onHide}>Close</button>

            </Modal.Footer>
        </Modal>
    );
}

export const InfoMessage = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{props.body_header}</h4>
                <p>
                    {props.body}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn text-center btn-outline-success" onClick={props.onHide}>Ok</button>
            </Modal.Footer>
        </Modal>
    );
}