import React, { useState, useEffect, Fragment } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { logout } from '../../../actions/auth';
import { GetSuppliers, MyProductList, Purchase } from '../../../actions/SuppliersAPI';
import { AllProductList } from '../../../actions/APIHandler';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../actions/types';
import { InfoMessage } from "../../Modals/ModalForm.js";
import Select from 'react-select';
import errorIcon from '../../../assets/error.png';
import infoIcon from '../../../assets/info.png';
import successIcon from '../../../assets/success.png';
import warningIcon from '../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';
// import { exportPDF } from '../Class/OrderPDF';
import { customHeader, locales } from "../../Suppliers/Class/datepicker";
import Datepicker from 'react-datepicker';
import * as moment from 'moment'
import { validate } from 'schema-utils';
import { AllMyProductList, FetchPurchaseNo } from '../../../actions/InventoryAPI';
let today = new Date();
const PurchaseProduct = ({ display, SupplierID, CompanyID, BranchID, user, list, setList }) => {
    const [CreateModalShow, setCreateModalShow] = useState(false);
    const [UpdateModalShow, setUpdateModalShow] = useState(false);
    const [DeleteModalShow, setDeleteModalShow] = useState(false);
    const [InfoModalShow, setInfoModalShow] = useState(false);
    const [Supplier, setSupplier] = useState(null)
    const [View, setView] = useState(false)
    const [Data, setData] = useState(false)
    const [Count, setCount] = useState(null)
    const [Error, setError] = useState({});
    const [MyProList, setMyProList] = useState(false)

    const [Date, setDate] = useState(today)
    const [PurchaseNo, setPurchaseNo] = useState("")
    const [OrderNo, setOrderNo] = useState("")
    const [Receiver, setReceiver] = useState(null)
    const [Payment, setPayment] = useState(null)
    const [Vat, setVat] = useState(0.00)
    const [Discount, setDiscount] = useState(0.00)
    const [Total, setTotal] = useState(0.00)
    const [Paid, setPaid] = useState(0.00)
    const [Due, setDue] = useState(0.00)

    const [PurchaseData, setPurchaseData] = useState([])
    const [locale, setLocale] = useState('en');

    let toastProperties = null;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        ItemCode: "",
        Title: "",
        UnitWeight: "",
        UnitPrice: "",
        Quantity: "",
        Unit: "",
        BarCode: "",
    });
    const { ItemCode, Title, UnitWeight, UnitPrice, Quantity, Weight, SubTotal } = formData;

    useEffect(() => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        LoadPurchaseNo();
        LoadSuppliers();
        // LoadProductItems();
    }, [])

    const LoadPurchaseNo = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        var result = await FetchPurchaseNo('PN');
        if (result !== true) {


            setPurchaseNo(result)
        } else {
            history.push('/supplier_items');
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }


    const LoadSuppliers = async () => {
        var result = await GetSuppliers(SupplierID);
        if (result !== true) {
            if (result.Status) {
                dispatch({ type: DISPLAY_OVERLAY, payload: true });
                var ProductItems = await AllProductList(SupplierID);
                if (ProductItems !== true)
                    setMyProList(ProductItems.data);
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            } else {
                dispatch({ type: DISPLAY_OVERLAY, payload: true });
                var ProductItems = await AllMyProductList();
                if (ProductItems !== true)
                    setMyProList(ProductItems.data);
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }
            setSupplier(result);
        } else {
            dispatch({ type: DISPLAY_OVERLAY, payload: false });
            history.push('/supplier_items');
        }
    }

    // const LoadProductItems = async () => {
    //     if (Supplier.Status === 0) {
    //         dispatch({ type: DISPLAY_OVERLAY, payload: true });
    //         var ProductItems = await AllMyProductList();
    //         if (ProductItems !== true)
    //             setMyProList(ProductItems.data);
    //         dispatch({ type: DISPLAY_OVERLAY, payload: false });
    //     } else if (Supplier.Status === 1) {
    //         dispatch({ type: DISPLAY_OVERLAY, payload: true });
    //         var ProductItems = await AllProductList(SupplierID);
    //         if (ProductItems !== true)
    //             setMyProList(ProductItems.data);
    //         dispatch({ type: DISPLAY_OVERLAY, payload: false });
    //     } else {
    //         history.push('/my_supplier');
    //     }
    // }


    const CScolourStyles = {
        container: base => ({
            ...base,
            flex: 1,
            fontWeight: "500"
        }),
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            onSubmit();
        }
    }

    const onSubmit = (e) => {
        if (formData.Quantity === "" || formData.Quantity === undefined || formData.ItemCode === "" || formData.ItemCode === undefined) {
            setInfoModalShow(true)
        } else {
            e.preventDefault();
            setPurchaseData([...PurchaseData, formData]);
            setCount(Count + 1);
            setFormData({
                Title: "",
                UnitWeight: "",
                UnitPrice: "",
                Quantity: "",
                ItemCode: "",
                BarCode: "",
            });
        }
    }

    const getTotal = () => {
        let TotalPrice = 0.00;
        const price = PurchaseData.map(row => row.Quantity * row.UnitPrice);
        if (price.length > 0) {
            TotalPrice = price.reduce((acc, val) => acc + val);
        }
        return TotalPrice;
    }
    const QuantityTotal = PurchaseData.reduce((TotalQuantity, myvalue) => TotalQuantity + parseInt(myvalue.Quantity, 10), 0);

    const deleteRow = (i) => {
        const newRows = PurchaseData.splice(i, 1).concat(PurchaseData.slice(i + 1));
        setCount(Count - 1)
    };

    const shouldBlur = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            onSubmit();
        }
    }

    const history = useHistory();

    const SavePurchase = async () => {
        if (user !== null) {
            const SupplierTitle = Supplier ? Supplier.CmpName : null
            let Cash_ID = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).CashAC_ID : "N/A"
            let Cash_Code = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).CashAC_Code : "N/A"
            let Stock_ID = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).StockAC_ID : "N/A"
            let Stock_Code = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).StockAC_Code : "N/A"
            let Purchase_ID = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).PurchaseAC_ID : "N/A"
            let Purchase_Code = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).PurchaseAC_Code : "N/A"
            let Payable_ID = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).PayableAC_ID : "N/A"
            let Payable_Code = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).PayableAC_Code : "N/A"
            let Receivable_ID = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).ReceivableAC_ID : "N/A"
            let Receivable_Code = JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).ReceivableAC_Code : "N/A"
            let VatTotal = (getTotal() * Vat) / 100
            let GrandTotal = Total === 0 ? getTotal() : Total
            var data = moment(Date).format('YYYY-MM-DD')

            dispatch({ type: DISPLAY_OVERLAY, payload: true });
            var result = await Purchase(user.CompanyID, user.BranchID, SupplierID, PurchaseNo, OrderNo, data, user.Id, Payment.value, Vat, parseFloat(VatTotal).toFixed(2), Discount, parseFloat(GrandTotal).toFixed(2), Paid, parseFloat(Due).toFixed(2), Count, PurchaseData, Cash_ID, Cash_Code, Purchase_ID, Purchase_Code, Payable_ID, Payable_Code, Receivable_ID, Receivable_Code, SupplierTitle, Stock_ID, Stock_Code);

            if (result !== true) {
                if (result.error) {
                    const updatedState = {};
                    for (var pair of result.exception.entries()) {
                        updatedState[pair[1].field] = pair[1].message;
                        setError({
                            ...updatedState,
                        });
                    }
                    setList([...list, toastProperties = {
                        id: 1,
                        title: 'Invalid Data',
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: warningIcon
                    }])
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                } else {
                    setList([...list, toastProperties = {
                        id: 1,
                        title: result.Title,
                        description: result.message,
                        backgroundColor: '#f0ad4e',
                        icon: result.ico === 1 ? infoIcon : successIcon
                    }])
                    // My_Orders();
                    LoadPurchaseNo();
                    dispatch({ type: DISPLAY_OVERLAY, payload: false });
                }
            } else {
                setList([...list, toastProperties = {
                    id: 1,
                    title: 'Error',
                    description: "Failed to save product profile. Please try after some moment.",
                    backgroundColor: '#f0ad4e',
                    icon: errorIcon
                }])
                dispatch({ type: DISPLAY_OVERLAY, payload: false });
            }

            dispatch({ type: DISPLAY_OVERLAY, payload: false });

        } else {
            history.push('/my_supplier');
        }
    }

    const VatCalc = (e) => {
        setVat(e.target.value)
        let SubTotal = getTotal();
        let bat = SubTotal + ((SubTotal * e.target.value) / 100)
        let Disc = Number(bat) - Number(Discount)
        let left = Number(Disc) - Paid
        setTotal(Disc);
        setDue(left);
    }

    const DiscountCalc = (e) => {
        setDiscount(e.target.value)
        let SubTotal = getTotal();
        let VatCal = SubTotal + ((SubTotal * Vat) / 100)
        let Disc = Number(VatCal) - Number(e.target.value)
        let left = Number(Disc) - Paid
        setTotal(Disc);
        setDue(left);
    }

    const PaidCalc = (e) => {
        setPaid(e.target.value)
        let SubTotal = getTotal();
        let VatCal = SubTotal + ((SubTotal * Vat) / 100)
        let Disc = Number(VatCal) - Discount
        let left = Number(Disc) - Number(e.target.value)

        // setTotal(left);
        setDue(left);
    }

    const BarcodeGen = (e) => {
        let Bar = CompanyID + BranchID + SupplierID + (PurchaseNo ? PurchaseNo : "") + ItemCode + e.target.value;
        setFormData({ ...formData, [e.target.name]: e.target.value, "BarCode": Bar, "Weight": UnitWeight * e.target.value, "SubTotal": UnitPrice * e.target.value });
    }

    const isStringNullOrWhiteSpace = (str) => {
        return str === undefined || str === null || str === "";
    }

    const Validate = () => {
        if (!isStringNullOrWhiteSpace(Date) && !isStringNullOrWhiteSpace(PurchaseNo) && !isStringNullOrWhiteSpace(Payment))
            return false;
        return true;
    }

    return (
        <div className="row h-100 m-0 d-flex justify-content-center">
            <div className="header mb-4">
                <p className="display-6 d-flex justify-content-center m-0">Product Purchase Vrouchar</p>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb d-flex justify-content-center">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/supplier_list">Product Purchase</Link></li>
                    </ol>
                </nav>
            </div>
            <div className="col-lg-12 h-100 pl-0">

                <div div className="row d-flex bg-white mx-auto">
                    <div className="col-lg-5 d-flex flex-wrap align-items-center justify-content-center">
                        {
                            Supplier ?
                                <div className="row g-0">
                                    <p className="fs-6 text-success text-center fw-bold m-0">Supplier Info</p>

                                    <div className="col-md-2 d-flex flex-wrap align-items-center justify-content-center">
                                        <img className="img-fluid mx-auto d-block rounded-start" alt="..."
                                            src={Supplier.Logo ? Supplier.Logo : process.env.REACT_APP_API_URL + "/Media/no_logo.jpeg"} width="120" />
                                    </div>
                                    <div className="col-md-10">
                                        <div className="card-body">
                                            <p className="display-6 fw-bold m-0">{Supplier.CmpName}</p>
                                            <p className="fs-6 m-0">{Supplier.CPFAddress}</p>
                                            <p className="fs-6 m-0">{Supplier.Contact}</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        <small className="text-muted text-left fw-bold">* Account- {JSON.parse(localStorage.getItem("accounts")) ? JSON.parse(localStorage.getItem("accounts")).PurchaseAC : "N/A"}</small>
                    </div >

                    <div className="col-lg-1">
                        <div className="cs_outer" style={{ height: "100%" }}>
                            <div className="cs_inner"></div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <form>
                            <div className="row mb-3 mt-3">
                                <label className="col-sm-3 col-form-label text-success fw-bold">Date</label>
                                <div className="col-sm-9">
                                    <Datepicker
                                        selected={Date}
                                        className="form-control fw-bold"
                                        dateFormat="dd MMM yyyy"
                                        renderCustomHeader={props => customHeader({ ...props, locale })}
                                        locale={locales[locale]}
                                        placeholderText="Please select date"
                                        onChange={(e) => setDate(e)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label text-success fw-bold">Order No</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control fw-bold" placeholder="Order No" id="inputEmail3" value={OrderNo} onChange={(e) => setOrderNo(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label text-success fw-bold">Invoice No</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control fw-bold" placeholder="Invoice No" id="inputEmail3" value={PurchaseNo} disabled />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-sm-3 col-form-label text-success fw-bold">Payment</label>
                                <div className="col-sm-9">
                                    <Select
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        menuPortalTarget={document.body}
                                        borderRadius={"0px"}
                                        options={[{ value: 1, label: "Due" }, { value: 2, label: "Paid" }, { value: 3, label: "Partial" }]}
                                        name="Division"
                                        placeholder={"Please select payment"}
                                        styles={CScolourStyles}
                                        value={Payment}
                                        onChange={(e) => { setPayment(e); Validate() }}
                                        required
                                        id="Title"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                <div className="row justify-content-center mx-auto d-table w-100 h-100">

                    <form className="row gx-3 bg-white justify-content-between align-items-center m-0 p-2 my-1">
                        <div className="col-sm-1">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Code</p>
                            <input
                                type="number"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                id="ItemCode"
                                placeholder="Item code"
                                value={formData ? ItemCode : ""}
                                required
                                disabled={Validate()}
                            />
                        </div>
                        <div className="col-sm-4">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Select Product</p>
                            <div className="input-group fs-5 fw-bold">
                                <Select
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    borderRadius={"0px"}
                                    options={Array.isArray(MyProList) && MyProList.length ? MyProList.map((item) => ({ ItemCode: item.ItemCode, value: item.ItemCode, label: item.Title, UnitWeight: item.UnitWeight, UnitPrice: item.UnitPrice })) : []}
                                    name="Division"
                                    placeholder={"Please select product"}
                                    styles={CScolourStyles}
                                    value={Title}
                                    onChange={(e) => setFormData(e)}
                                    required
                                    id="Title"
                                    isDisabled={Validate()}

                                />
                            </div>
                        </div>
                        <div className="col-sm-1">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Unit Wt</p>
                            <input
                                type="text"
                                id="UnitWeight"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                placeholder="Unit Wt"
                                value={UnitWeight ? UnitWeight.toLocaleString("en", { minimumFractionDigits: 3 }) : ""}
                                required
                                disabled={Validate()}
                            />
                        </div>
                        <div className="col-sm-1">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Unit Price</p>
                            <input
                                type="text"
                                id="UnitPrice"
                                name="UnitPrice"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                placeholder="Unit Price"
                                value={UnitPrice ? UnitPrice.toLocaleString("en", { minimumFractionDigits: 2 }) : ""}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                required
                                disabled={Validate()}
                            />
                        </div>
                        <div className="col-sm-1">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Quantity</p>
                            <input
                                type="number"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                name="Quantity"
                                id="Quantity"
                                placeholder="Quantity"
                                value={Quantity}
                                onChange={(e) => BarcodeGen(e)}
                                onKeyDown={(e) => shouldBlur(e)}
                                required
                                disabled={Validate()}
                            />
                        </div>
                        <div className="col-sm-1">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Weight</p>
                            <input
                                type="text"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                name="Weight"
                                id="Weight"
                                placeholder="Weight"
                                value={Weight ? Weight.toLocaleString("en", { minimumFractionDigits: 2 }) : ""}
                                required
                                disabled
                            />
                        </div>
                        <div className="col-sm-2 border-right border-2">
                            <p className="text-center text-dark fw-bold m-0 border-bottom">Sub-Total</p>
                            <input
                                type="text"
                                className="form-control fs-5 fw-bold p-0 text-center"
                                name="SubTotal"
                                id="SubTotal"
                                placeholder="Sub-total"
                                value={SubTotal ? SubTotal.toLocaleString("en", { minimumFractionDigits: 2 }) : ""}
                                required
                                disabled
                            />
                        </div>

                        <div className="col-auto">
                            <button
                                className="btn fs-2 fad fa-plus text-center text-success"
                                onKeyDown={(e) => handleKeyDown(e)}
                                type="submit"
                                onClick={(e) => { !Validate() ? onSubmit(e) : e.preventDefault() }}
                            />
                        </div>
                    </form>


                    <InfoMessage
                        header="Invalid Data"
                        body_header="Input data is not valid. Please fill input field correctly."
                        body="Please fill all field correctly"
                        show={InfoModalShow}
                        onHide={() => setInfoModalShow(false)}
                    />

                    {
                        Array.isArray(PurchaseData) && PurchaseData.length ?
                            <table className={`table table-hover table-borderless table-responsive card-1 d-table`}>
                                <thead>
                                    <tr className="text-center" style={{ borderBottom: "3px solid #DEE2E6" }}>
                                        <th className="py-1 border-right"><span>S/N</span></th>
                                        <th className="py-1 border-right"><span>Item Code</span></th>
                                        <th className="py-1 border-right"><span>Title</span></th>
                                        <th className="py-1 border-right"><span className="d-block text-right fw-bolder">Unit Weight</span></th>
                                        <th className="py-1 border-right"><span className="d-block text-right fw-bolder">Unit Price</span></th>
                                        <th className="py-1 border-right"><span>Quantity</span></th>
                                        <th className="py-1 border-right"><span className="d-block text-right fw-bolder">Price</span></th>
                                        <th className="py-1"><span>Action</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        PurchaseData.map((item, i) => (
                                            <tr className="border-bottom text-center" key={i}>
                                                <td className="p-0 border-right"><span className="d-block fw-bold">{i + 1}</span></td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold">{item.ItemCode}</span></td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold">{item.label}</span></td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold">{(item.UnitWeight).toLocaleString("en", { minimumFractionDigits: 3 })}</span> </td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold">{(item.UnitPrice).toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold">{item.Quantity}</span> </td>
                                                <td className="p-0 border-right"><span className="d-block fw-bold text-right">{(item.Quantity * item.UnitPrice).toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                                <td className="px-3 py-0">
                                                    <button className="btn fs-3 py-1 fad fa-minus text-dark  fw-bold" onClick={() => deleteRow(i)} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="text-center border-success bg-white">
                                        <td className="p-1 px-3 border-right" colSpan="6"><span className="d-block fw-bolder text-right">Sub-total </span> </td>
                                        <td className="p-1 border-right"><span className="d-block fw-bolder text-right">{getTotal().toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                    </tr>
                                    <tr className="text-center border-success bg-white">
                                        <td className="p-1 px-3 border-right" colSpan="6"><span className="d-block text-right">VAT Rate (%) </span> </td>
                                        <td className="p-1 d-flex justify-content-end border-right"><input type="text" autocomplete="off" className="d-block text-right border-0" id="Vat" value={Vat} onChange={(e) => VatCalc(e)} /></td>
                                    </tr>
                                    <tr className="text-center border-success bg-white">
                                        <td className="p-1 px-3 border-right" colSpan="6"><span className="d-block text-right ">Discount (tk) </span> </td>
                                        <td className="p-1 d-flex justify-content-end border-right">
                                            <input type="text"
                                                autocomplete="off"
                                                className="d-block text-right border-0"
                                                id="Discount"
                                                value={Discount}
                                                onChange={(e) => DiscountCalc(e)} />
                                        </td>
                                    </tr>
                                    <tr className="text-center border-success bg-white">
                                        <td className="p-1 px-3 border-right" colSpan="6"><span className="d-block text-right fw-bolder">Total Price </span> </td>
                                        <td className="p-1 border-right"><span className="d-block fw-bolder text-right">{Total === 0.00 ? getTotal().toLocaleString("en", { minimumFractionDigits: 2 }) : Total.toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                    </tr>

                                    {Payment.value === 3 ?
                                        <tr className="text-center border-success bg-white">
                                            <td className="p-1 px-3 border-right" colSpan="6"><span className="d-block text-right fw-bolder fs-4">PAID </span> </td>
                                            <td className="p-1 d-flex justify-content-end border-right">
                                                <input type="text"
                                                    autocomplete="off"
                                                    className="d-block text-right border fs-4 fw-bolder"
                                                    id="Paid"
                                                    value={Paid}
                                                    onChange={(e) => PaidCalc(e)} />
                                            </td>
                                        </tr>
                                        : null}


                                    <tr className="text-center border border-light mt-3">
                                        <td className="p-1"><span className="d-block text-right fw-bolder">Count:</span> </td>
                                        <td className="p-1"><span className="d-block text-left fw-bolder">{Count}</span> </td>
                                        <td className="p-1"><span className="d-block text-right fw-bolder">Total Quantity:</span> </td>
                                        <td className="p-1"><span className="d-block text-left fw-bolder">{QuantityTotal}</span> </td>
                                        <td className="p-1" colSpan="2"><span className="d-block text-right fw-bolder">Due: </span> </td>
                                        <td className="p-1"><span className="d-block fw-bolder text-right">{Paid === 0.00 ? Total === 0.00 ? getTotal().toLocaleString("en", { minimumFractionDigits: 2 }) : Total.toLocaleString("en", { minimumFractionDigits: 2 }) : getTotal() === Paid ? 0.00 : Due === 0.00 ? (0).toLocaleString("en", { minimumFractionDigits: 2 }) : Due.toLocaleString("en", { minimumFractionDigits: 2 })}</span> </td>
                                        <td className="p-1"><span className="px-3 py-0">
                                            <button className="btn fs-3 py-1 fad fa-paper-plane text-success"
                                                onClick={() => SavePurchase()}
                                            />
                                        </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <div className={`d-flex justify-content-center align-items-center bg-white`}>
                                <p className='fs-2 fw-bold text-center text-success m-0'>No Product Found!</p>
                            </div>
                    }
                </div >
            </div >
        </div >
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    SupplierID: props.match.params.sup_id,
    CompanyID: state.auth.user.CompanyID,
    BranchID: state.auth.user.BranchID,
    user: state.auth.user
});

export default connect(mapStateToProps, { logout })(PurchaseProduct);