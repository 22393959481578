import { DISPLAY_OVERLAY } from './types';
import axios from 'axios';
import { checkToken } from './auth';
import { useDispatch } from 'react-redux';

export const FetchPurchaseNo = async (InvoiceType) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_purchase_no/${InvoiceType}`, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const FetchProductRequestNo = async (InvoiceType) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_product_request_no/${InvoiceType}`, config);

        return res.data
    } catch (err) {

        return true;
    }
}

export const MyStock = async () => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_stock/`, config);

        return res
    } catch (err) {
        return true;
    }
}


export const AllMyProductList = async () => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
            // 'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/my_product_list/`, config);

        return res
    } catch (err) {
        return true;
    }
}

const getInvNo = () => {
    var d = new window.Date();
    if (JSON.parse(localStorage.getItem("user"))) {
        var components = [
            JSON.parse(localStorage.getItem("user")).CompanyID,
            JSON.parse(localStorage.getItem("user")).BranchID,
            d.getYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds(),
            String(d.getMilliseconds()).toString().substr(-1)
        ];
        var id = components.join("");
        return id
    } else {
        return false
    }
}


export const UpdateStock = async (id, Qty, Weight, Cost, MinRequired, InitStock, Status) => {
    if (getInvNo()) {

        
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        const formData = new FormData();
        formData.append("Qty", Qty);
        formData.append("Weight", Weight);
        formData.append("Cost", Cost);
        formData.append("MinRequired", MinRequired);
        formData.append("InitStock", InitStock);
        formData.append("Status", Status);

        try {
            for (var pair of formData.entries()) {

            }
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/my_stock/${id}/`, formData, config);

            return res.data
        } catch (err) {
            return true;
        }
    }
}

export const DeleteStock = async (id) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/my_stock/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const Purchase = async (CompanyID, BranchID, SupplierID, InvoiceNo, OrderNo, Date, Receiver, Payment, VatRate, Vat, Discount, Total, Count, PurchaseData) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("CompanyID", CompanyID);
    formData.append("BranchID", BranchID);
    formData.append("SupplierID", SupplierID);
    formData.append("InvoiceNo", InvoiceNo);
    formData.append("Date", Date);
    formData.append("Receiver", Receiver);
    formData.append("Payment", Payment);
    formData.append("VatRate", VatRate);
    formData.append("Vat", Vat);
    formData.append("Discount", Discount);
    formData.append("GrandTotal", Total);
    formData.append("ItemCount", Count);

    Object.keys(PurchaseData).map(e => {
        formData.append(`PurchaseMapData[${e}]CompanyID`, CompanyID);
        formData.append(`PurchaseMapData[${e}]BranchID`, BranchID);
        formData.append(`PurchaseMapData[${e}]OrderNo`, OrderNo);
        formData.append(`PurchaseMapData[${e}]InvoiceNo`, InvoiceNo);
        formData.append(`PurchaseMapData[${e}]ReceiverID`, Receiver);
        formData.append(`PurchaseMapData[${e}]ItemCode`, PurchaseData[e].ItemCode);
        formData.append(`PurchaseMapData[${e}]Weight`, PurchaseData[e].Weight);

        formData.append(`PurchaseMapData[${e}]Qty`, PurchaseData[e].Quantity);
        formData.append(`PurchaseMapData[${e}]Rate`, PurchaseData[e].UnitPrice);
        // formData.append(`PurchaseMapData[${e}]SubTotal`, PurchaseData[e].ReceiverID);
        formData.append(`PurchaseMapData[${e}]Status`, "Received");
        formData.append(`PurchaseMapData[${e}]MFG`, PurchaseData[e].MFG);
        formData.append(`PurchaseMapData[${e}]EXP`, PurchaseData[e].EXP);
        formData.append(`PurchaseMapData[${e}]UpdatedBy`, Receiver);
    }
    );


    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/purchase/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchSubscriberList = async () => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/business/`, config);

        return res.data
    } catch (err) {

        return true;
    }
}


export const FetchSubscriber = async (id) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_subscriber/${id}`, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const RequestOrder = async (RequestTo, RequestNo, data, DeliveryDate, Amount, Status, RequestData) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("RequestTo", RequestTo);
    formData.append("Date", data);
    formData.append("DeliveryDate", DeliveryDate);
    formData.append("RequestNo", RequestNo);
    formData.append("Amount", Amount);
    formData.append("Status", Status);

    Object.keys(RequestData).map(e => {
        formData.append(`RequestMapData[${e}]ItemCode`, RequestData[e].ItemCode);
        formData.append(`RequestMapData[${e}]OrderQty`, RequestData[e].Quantity);
        formData.append(`RequestMapData[${e}]UnitPrice`, RequestData[e].UnitPrice);
        formData.append(`RequestMapData[${e}]SubTotal`, RequestData[e].SubTotal);
        formData.append(`RequestMapData[${e}]ReceivedQty`, "");
        formData.append(`RequestMapData[${e}]Status`, 0);
    });


    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/product_request/`, formData, config);

        return res.data
    } catch (err) {


        return true;
    }
}


export const DeliverProduct = async (RequestID, DeliveryDate, Amount, RequestData) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("RequestNo", RequestID);
    formData.append("DeliveryDate", DeliveryDate);
    formData.append("Amount", Amount);
    formData.append("Status", 2);

    Object.keys(RequestData).map(e => {
        formData.append(`RequestMapData[${e}]id`, RequestData[e].id);
        formData.append(`RequestMapData[${e}]ItemCode`, RequestData[e].ItemCode);
        formData.append(`RequestMapData[${e}]OrderQty`, RequestData[e].Quantity);
        formData.append(`RequestMapData[${e}]UnitPrice`, RequestData[e].UnitPrice);
        formData.append(`RequestMapData[${e}]SubTotal`, RequestData[e].SubTotal);
        formData.append(`RequestMapData[${e}]ReceivedQty`, RequestData[e].ReceivedQty);
        formData.append(`RequestMapData[${e}]Status`, 1);
    });


    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/product_request/${RequestID}/`, formData, config);

        return res.data
    } catch (err) {


        return true;
    }
}

export const FetchProductRequest = async () => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/product_request/`, config);

        return res.data
    } catch (err) {

        return true;
    }
}

export const FetchDraft = async (Status) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/req_draft/${Status}`, config);

        return res.data
    } catch (err) {

        return true;
    }
}

export const FetchDraftRequest = async (ReqNo) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_draft/${ReqNo}`, config);

        return res.data
    } catch (err) {

        return true;
    }
}


export const SaveDraftItem = async (RequestID, ItemCode, Quantity, UnitPrice, SubTotal, Status) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("RequestNo", RequestID);
    formData.append("ItemCode", ItemCode);
    formData.append("OrderQty", Quantity);
    formData.append("UnitPrice", UnitPrice);
    formData.append("SubTotal", SubTotal);
    formData.append("Status", Status);

    try {
        for (var pair of formData.entries()) {

        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/draft_item/`, formData, config);

        return res.data
    } catch (err) {
        return true
    }
}


export const DeleteDraftItem = async (id) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/draft_item/${id}/`, config);

        return res.data
    } catch (err) {
        return true
    }
}

export const SendDraftRequest = async (id) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    const formData = new FormData();
    formData.append("Status", 1);

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/draft_item/${id}/`, formData, config);

        return res.data
    } catch (err) {
        return true;
    }
}

export const FetchOrderNo = async (InvoiceType) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_order_no/${InvoiceType}`, config);

        return res.data
    } catch (err) {


        return true;
    }
}


export const ProductInit = async (BranchID, ItemCode, UnitPrice, Quantity, Weight, MinRequired, Status, COA, COA_Code) => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const formData = new FormData();
    formData.append("BranchID", BranchID);
    formData.append("ItemCode", ItemCode);
    formData.append("InitStock", Weight);
    formData.append("LastReceived", Quantity);
    formData.append("MinRequired", MinRequired);
    formData.append("Weight", Weight);
    formData.append("Qty", Quantity);
    formData.append("Cost", UnitPrice);
    formData.append("Status", Status);
    formData.append("COA_Code", COA_Code);
    formData.append("COA", COA);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/init_product/`, formData, config);
        return res.data
    } catch (err) {
        return true
    }
}
