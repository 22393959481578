import { Button, Modal, Spinner } from "react-bootstrap";
import React, { useEffect, useRef, useState, Fragment } from 'react';
import Select from 'react-select';
import { checkToken } from '../../../../actions/auth';
import { UpdateContract, LoadCondList } from '../../../../actions/ContractAPI';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { DISPLAY_OVERLAY } from '../../../../actions/types';
import errorIcon from '../../../../assets/error.png';
import infoIcon from '../../../../assets/info.png';
import successIcon from '../../../../assets/success.png';
import warningIcon from '../../../../assets/warning.gif';
import { UpdateParty } from "../../../../actions/APIHandler";

export const PartyUpdate = (props) => {
    const [Loading, setLoading] = useState(false)
    const [Next, setNext] = useState(false)
    const initialValue = { value: 0, label: "" };
    const [RepLists, setRepLists] = useState(initialValue);
    const [CondList, setCondList] = useState(initialValue);
    const [Error, setError] = useState({});
    let toastProperties = null;
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        CompanyID: props.CompanyID,
        BranchID: props.BranchID,
        Title: props.item.Title,
        Name: props.item.Name,
        Contact: props.item.Contact,
        Limit: props.item.Limit,
        SCMoney: props.item.SCMoney,
        Balance: props.item.Balance,
        Target: props.item.Target,
        Currency: props.item.Currency,
        RepName: props.item.RepID.FirstName + " " + props.item.RepID.LastName,
        RepID: props.item.RepID.id,
        FarmReg: props.item.FarmReg ? true : false,
        BlankCheque: props.item.BlankCheque ? true : false,
        Agreement: props.item.Agreement ? true : false,
    });

    useEffect(() => {
        LoadRep();
        Condition();
    }, [])

    const { CompanyID, BranchID, Title, Name, Contact, SCMoney, Limit, Balance, Target, Currency, RepName, RepID, FarmReg, BlankCheque, Agreement } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


    const LoadRep = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`
                // 'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/rep_lists/`, config);
            setRepLists(res.data.Rep);

        } catch (err) {
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const SendContract = async () => {
        const result = await UpdateParty(props.item.id, Title, Name, Contact, SCMoney, Limit, Balance, Target, Currency, RepID, FarmReg, BlankCheque, Agreement);
        if (result !== true) {
            if (result.error) {
                const updatedState = {};
                for (var pair of result.exception.entries()) {
                    updatedState[pair[1].field] = pair[1].message;
                    setError({
                        ...updatedState,
                    });
                }
                props.setList([...props.list, toastProperties = {
                    id: 1,
                    title: 'Invalid Data',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: warningIcon
                }])

            } else {
                props.setList([...props.list, toastProperties = {
                    id: 1,
                    title: 'Success',
                    description: result.message,
                    backgroundColor: '#f0ad4e',
                    icon: successIcon
                }])
                props.onReload();
            }
        } else {
            props.setList([...props.list, toastProperties = {
                id: 1,
                title: 'Error',
                description: "Failed to update contract farm. Please try after some moment.",
                backgroundColor: '#f0ad4e',
                icon: errorIcon
            }])
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const Condition = async () => {
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        const result = await LoadCondList();
        if (result !== true) {
            setCondList(result.cond)
            // setFormData({ ...formData, CondTitle: result.active.Title, CondID: result.active.value })
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    }

    const ClearField = () => {
        setFormData({
            CompanyID: props.CompanyID,
            BranchID: props.BranchID,
            Title: props.item.Title,
            Name: props.item.Name,
            Contact: props.item.Contact,
            Limit: props.item.Limit,
            SCMoney: props.item.SCMoney,
            Balance: props.item.Balance,
            Target: props.item.Target,
            Currency: props.item.Currency,
            RepName: props.item.RepID.FirstName + " " + props.item.RepID.LastName,
            RepID: props.item.RepID.id,
            FarmReg: props.item.FarmReg ? true : false,
            BlankCheque: props.item.BlankCheque ? true : false,
            Agreement: props.item.Agreement ? true : false,
        })
        setNext(false)
        const initialValue = { value: 0, label: "" };
        // setRepLists(initialValue);

        props.onHide()
    }

    const CScolourStyles = {
        container: base => ({
            ...base,
            flex: 1,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        // onClick={() => ClearField()}
        >
            <Modal.Header className="py-2">
                <p id="contained-modal-title-vcenter" className="fs-4 fw-bold text-dark m-0 justify-content-center">
                    Update Party Details <br />
                    <small>Please change the required info</small>
                </p>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-group">
                        <label for="Title" class="col-form-label">Title</label>
                        <input
                            type="text"
                            class="form-control"
                            id="Title"
                            name="Title"
                            placeholder='Farm/Shed/Business Title'
                            value={Title}
                            onChange={e => onChange(e)}
                        />
                        {Error.Title ?
                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Title}</small></p>
                            : null}
                    </div>
                    <div className="form-group">
                        <label for="Name" class="col-form-label">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="Name"
                            name="Name"
                            placeholder='Name'
                            value={Name}
                            onChange={e => onChange(e)}
                        />
                        {Error.Name ?
                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Name}</small></p>
                            : null}
                    </div>
                    <div className="form-group">
                        <label for="Contact" class="col-form-label">Conact No</label>
                        <input
                            type="text"
                            class="form-control"
                            id="Contact"
                            name="Contact"
                            placeholder='Contact No'
                            value={Contact}
                            onChange={e => onChange(e)}
                        />
                        {Error.Contact ?
                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Contact}</small></p>
                            : null}
                    </div>
                    <div className="form-group">
                        <label for="message-text" class="col-form-label">Security Money</label>
                        <input
                            type="numeric"
                            class="form-control"
                            id="SCMoney"
                            name="SCMoney"
                            placeholder='Security Money'
                            value={SCMoney}
                            onChange={e => onChange(e)}
                        />
                        {Error.SCMoney ?
                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.SCMoney}</small></p>
                            : null}
                    </div>
                    <div className="form-group">
                        <label for="recipient-name" class="col-form-label">Balance</label>
                        <input
                            type="numeric"
                            class="form-control"
                            id="Balance"
                            name="Balance"
                            placeholder='Balance'
                            value={Balance}
                            onChange={e => onChange(e)}
                        />
                        {Error.Balance ?
                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Balance}</small></p>
                            : null}
                    </div>
                    <div className="form-group">
                        <label for="message-text" class="col-form-label">Credit Limit</label>
                        <input
                            type="numeric"
                            class="form-control"
                            id="Limit"
                            name="Limit"
                            placeholder='Credit Limit'
                            value={Limit}
                            onChange={e => onChange(e)}
                        />
                        {Error.Limit ?
                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Limit}</small></p>
                            : null}
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label for="message-text" class="col-form-label">Target</label>
                                <input
                                    type="numeric"
                                    class="form-control"
                                    id="Target"
                                    name="Target"
                                    placeholder='Target'
                                    value={Target}
                                    onChange={e => onChange(e)}
                                />
                                {Error.Target ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Target}</small></p>
                                    : null}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="recipient-name" class="col-form-label">Currency</label>
                                <Select
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    borderRadius={"0px"}
                                    options={[{ label: "BDT", value: 1 }, { label: "KG", value: 2 }, { label: "PCS", value: 3 }]}
                                    defaultValue={{ label: "Select Dept", value: 0 }}
                                    name="Cond"
                                    placeholder={"Select Currency"}
                                    styles={CScolourStyles}
                                    value={Currency ? { label: parseInt(Currency) === 1 ? "BDT" : parseInt(Currency) === 2 ? "KG" : parseInt(Currency) === 3 ? "PCS" : "", value: Currency } : null}
                                    onChange={e => setFormData({ ...formData, Currency: e.value })}
                                />
                                {Error.Currency ?
                                    <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.Currency}</small></p>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="recipient-name" class="col-form-label">Representative</label>
                        <Select
                            menuPlacement="auto"
                            menuPosition="fixed"
                            menuPortalTarget={document.body}
                            borderRadius={"0px"}
                            options={RepLists}
                            name="RepName"
                            placeholder={"Select rep. name"}
                            styles={CScolourStyles}
                            value={RepName ? { label: RepName } : null}
                            onChange={e => setFormData({ ...formData, "RepName": e.label, "RepID": e.value })}
                            required
                            id="Rep"
                        />
                        {Error.RepID ?
                            <p className='mx-auto d-table text-center text-warning m-0'><small>{Error.RepID}</small></p>
                            : null}
                    </div>

                    <div className="form-group">
                        <div className="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value={Agreement}
                                id="FarmReg"
                                name="FarmReg"
                                checked={FarmReg}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: !FarmReg ? true : false })}
                            />
                            <label class="form-check-label" for="FarmReg">
                                Farm Registratioin Copy
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value={Agreement}
                                id="BlankCheque"
                                name="BlankCheque"
                                checked={BlankCheque}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: !BlankCheque ? true : false })}
                            />
                            <label class="form-check-label" for="BlankCheque">
                                Blank Cheque
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                value={Agreement}
                                id="Agreement"
                                name="Agreement"
                                checked={Agreement}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: !Agreement ? true : false })}
                            />
                            <label class="form-check-label" for="Agreement">
                                Agreement
                            </label>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-success" onClick={() => ClearField()}>Close</button>
                <button className="btn btn-outline-success" onClick={() => SendContract()}>Submit </button>
            </Modal.Footer>
        </Modal >
    );
}