import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import { login } from '../../actions/auth';
import { DISPLAY_OVERLAY } from '../../actions/types';

const Home = ({ data, login, error, message, level, sub_level }) => {
    const [isVisible, setisVisible] = useState(true)
    const [CurrentSlide, setCurrentSlide] = useState(0)
    const [SlideTimer, setSlideTimer] = useState(1 * 1000)
    // const Slides = [
    //     "4 years of long journery since 2017",
    //     "Softapoul v6.00 ultimate solution for poultry business",
    //     "Enterprise Resource Planning (ERP) with Contract Farming"
    // ]
    // // setTimeout(function () { changeSlide(); }, 10000);
    // useEffect(() => {
    //     setTimeout(() => {
    //         changeSlide();
    //     }, 8000);
    // }, [CurrentSlide])

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        MobileNo: '',
        password: '',
        rememberme: false
    });

    useEffect(() => {
        if (localStorage.getItem('MobileNo')) {

            setFormData({ ...formData, rememberme: true, MobileNo: localStorage.getItem('MobileNo') });
        } else {
            setFormData({ ...formData, rememberme: false });
        }

    }, [])

    const history = useHistory();

    const { MobileNo, password, rememberme } = formData;


    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });



    // const changeSlide = () => {
    //     let nextSlide = Slides.length - 1 > CurrentSlide ? CurrentSlide + 1 : 0;

    //     setisVisible(false)
    //     setCurrentSlide(nextSlide)
    //     setTimeout(function () { setisVisible(true) }, 500);
    // }

    const onSubmit = e => {
        e.preventDefault();
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        login(MobileNo, password, rememberme);
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    };

    const handleChange = (e) => {
        if (!e.target.value) {
            localStorage.removeItem('MobileNo');
            setFormData({
                ...formData, rememberme: false, MobileNo: '', password: ''
            });
        }
        setFormData({
            ...formData, [e.target.name]: e.target.value
        });

    }

    return (
        <div className="row h-100 m-0 d-flex justify-content-center" style={{ zIndex: "1000" }}>
            <div className="col-md-8 py-1 align-middle">
                <div className='row px-2 h-100'>
                    <div className="d-table-cell align-middle px-3">
                        <img src={`${process.env.REACT_APP_API_URL}/Media/logo.png`} className="img-fluid mx-auto d-table p-2 mt-3" width="400" height="80" alt="Softapoul" />
                        <span className="d-flex justify-content-center text-center text-uppercase text-muted mr-1 p-1">Poultry Enterprise Resource Planning (PERP), V7.00</span>
                        {/* <p className="display-3 d-flex justify-content-center text-success fw-normal">Softa <p className="fw-bolder mr-1">Poul</p> v7.00</p> */}
                        <p className="fs-4 d-flex justify-content-center border border-2 border-white mt-4" style={{ fontSize: "5vw", borderRadius: "15px" }}>
                            <span className="fw-bold text-center text-warning mr-1 p-1">5 years of long journery since 2017.</span>
                            <span className="fw-normal text-center text-dark mr-1 border-2 border-right border-left border-white p-1">Softapoul v7.00 ultimate solution for poultry business.</span>
                            <span className="fw-normal text-center text-primary mr-1 p-1">Enterprise Resource Planning (ERP) with Contract Farming.</span>
                        </p>

                        <div class="container h-50">
                            <div class="row align-items-center h-100">
                                <p className='text-center fw-bold fs-3' style={{ fontFamily: "Tahoma" }}>
                                    "Softapoul is an Enterprise Resource Planning (ERP) software specially developed for poultry business. It's contract farming friendly who want to conduct their business with contract farming policy. Ease and easy to maintain your business across the country."
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-md-4 border border-light bg-gradient bg-white px-0 py-1 align-middle" style={{ borderRadius: "15px", zIndex: "1000" }}>
                <div className="col-sm-12 col-md-10 col-lg-10 mx-auto d-table h-100">
                    <div className="d-table-cell align-middle">
                        <h5 className='text-danger'>{error ? message : null}</h5>
                        <h1>Sign In</h1>
                        <p className='text-muted'>Sign in with your data that you entered during your registration.</p>
                        <form onSubmit={e => onSubmit(e)}>
                            <div className='form-group my-2'>
                                <input
                                    style={{ borderRadius: '20px' }}
                                    className='form-control form-rounded pl-3'
                                    type='phone'
                                    placeholder='Mobile Number'
                                    name='MobileNo'
                                    value={MobileNo}
                                    defaultValue={rememberme ? localStorage.getItem('MobileNo') : MobileNo}
                                    onChange={e => {
                                        handleChange({
                                            target: {
                                                name: e.target.name,
                                                value: e.target.value,
                                            },
                                        });
                                    }}
                                    required
                                />
                            </div>
                            <div className='form-group  my-2'>
                                <input
                                    className='form-control form-rounded pl-3'
                                    type='password'
                                    placeholder='Password'
                                    name='password'
                                    value={password}
                                    onChange={e => { handleChange({ target: { name: e.target.name, value: e.target.value } }); }}
                                    minLength='6'
                                    maxLength='12'
                                    required
                                />
                            </div>
                            <div className='form-check  my-2'>
                                <input
                                    type="checkbox"
                                    name="rememberme"
                                    id="rememberme"
                                    className="form-check-input"
                                    checked={rememberme}
                                    onChange={(e) => {
                                        handleChange({
                                            target: {
                                                name: e.target.name,
                                                value: e.target.checked,
                                            },
                                        });
                                    }}
                                />
                                <label for="rememberme">Remember me</label>
                            </div>
                            <button className='btn btn-dark' type='submit'>Submit</button>
                        </form>

                        <p className='mt-3'>
                            Forgot your password? <Link to='/reset-password'>Reset password</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    data: state.auth.user,
    display: state.OverlayDisplay,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.auth.error,
    message: state.auth.message,
    level: state.auth.level,
    sub_level: state.auth.sub_level,
});
export default connect(mapStateToProps, { login })(Home);
