import React, { Component } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { checkAuthenticated, load_user, logout } from '../actions/auth';
import { connect, useDispatch, useSelector } from 'react-redux';
import Layout from "./Layout";

export var FieldWorkerRoute = ({ component, render, ...rest }) => {
  const { level, sub_level } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        return typeof (level) !== 'undefined' && level !== null && (level === 6 || (level === 3 && (sub_level === 4 || sub_level === 5 || sub_level === 6 || sub_level === 7 || sub_level === 8 || sub_level === 9 || sub_level === 10))) ?
          (component ? React.createElement(component, props) : render(props))
          :
          null
      }}
    />
  )
}