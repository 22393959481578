export const CategoryList = [
    { value: 1, label: "Medicine" },
    { value: 2, label: "Feed" },
    { value: 3, label: "Chick" },
    { value: 4, label: "Cement" },
    { value: 5, label: "Rod" },
    { value: 6, label: "Brick" },
    { value: 7, label: "Sand" },
    { value: 8, label: "Stone" },
    { value: 9, label: "Ceramic" },
    { value: 10, label: "Tiles" }
]