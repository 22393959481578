import * as moment from 'moment'
import { MyOrders, colourStyles, SendOrder, MyProductList } from '../../../../actions/SuppliersAPI';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { checkToken, logout } from '../../../../actions/auth';
import { connect, useDispatch } from 'react-redux';
import { LoadProfile } from '../../../../actions/APIHandler';
import { BusinessPro, BISTerminate } from '../../../../actions/ContractAPI';

import { DISPLAY_OVERLAY } from '../../../../actions/types';
import Select from 'react-select';
import axios from 'axios';
import errorIcon from '../../../../assets/error.png';
import infoIcon from '../../../../assets/info.png';
import successIcon from '../../../../assets/success.png';
import warningIcon from '../../../../assets/warning.gif';
import { Accordion } from 'react-bootstrap';
import { DeleteMessage } from "./Modals/DeleteModal.js";
import { PartyUpdate } from './PartyUpdate';

const Details = ({ CompanyID, BranchID, SupplierID, user, UserID, PartyID, list, setList }) => {
    const [ModalShow, setModalShow] = useState(false);
    const [BUp, setBUp] = useState(false);
    const [Item, setItem] = useState(false);
    const [InfoModalShow, setInfoModalShow] = useState(false);
    const initialValue = { value: 0, label: "" };
    const [Data, setData] = useState(false)
    const [OrderData, setOrderData] = useState([])
    const [Count, setCount] = useState(null)
    const [Error, setError] = useState({});
    let toastProperties = null;
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        My_Orders();
    }, [])

    const My_Orders = async () => {
        var BisDetails = await BusinessPro(PartyID);
        if (BisDetails !== true) {
            setData(BisDetails);
        } else {
            history.push('/my_party_lists');
        }
    }

    const today = new Date().toLocaleDateString("en-us", "dd/MM/yyyy");

    const CScolourStyles = {
        container: base => ({
            ...base,
            flex: 1,
        }),
    }


    const DeleteUser = async e => {
        setModalShow(false)
        dispatch({ type: DISPLAY_OVERLAY, payload: true });
        e.preventDefault();
        const result = await BISTerminate(PartyID);
        if (result !== true) {
            history.goBack();
        }
        dispatch({ type: DISPLAY_OVERLAY, payload: false });
    };

    return (
        <div className="position-relative mb-5" style={{ height: "92%" }}>

            <div className="position-absolute overflow-auto my-1 w-100 h-100 bg-white">

                <div className="d-flex justify-content-center bg-white py-2 h-100">
                    <div className="col-md-6 justify-content-center align-items-center">
                        <table className={`table table-borderless table-responsive card-1 d-flex justify-content-center position-absolute overflow-auto top-0 start-50 translate-middle-x`} style={{ maxHeight: "70%" }}>
                            {Data ?
                                <tbody>
                                    <tr className="border-bottom text-center">
                                        <td className="p-1" colspan="2"><p className="fs-4 fw-bolder text-center py-2 m-0">PARTY DETAILS</p></td>
                                    </tr>
                                    <tr className="border-bottom border-top text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Business ID</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.id}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Business Type</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Type}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Branch ID</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.BranchID.id}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Subscriber Name</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Name}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Business Title</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Title}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Address</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Address}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Contact No</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Contact}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Security Money</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.SCMoney}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Balance</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Balance}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Target</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{parseFloat(Data.Target)} {parseInt(Data.Currency) === 1 ? "BDT" : parseInt(Data.Currency) === 2 ? "KG" : parseInt(Data.Currency) === 3 ? "PCS" : ""}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Agreement</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Agreement ? "Signed Up" : "No Agreement"}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Blank Cheque</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.BlankCheque ? "Received" : "No Blank Cheque Received"}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Trade Licence</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.FarmReg ? "Copy has Resereved" : "No Copy has been Resereved"}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Representative</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.RepID.FirstName + " " + Data.RepID.LastName}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Since</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{new Date(Data.CreatedAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' ')}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Last Update</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{new Date(Data.UpdatedAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, ' ')}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Updated by</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.UpdatedBy}</span></td>
                                    </tr>
                                    <tr className="border-bottom text-center">
                                        <td className="border-right p-1"><span className="d-block fs-6 fw-bolder text-uppercase text-left text-dark px-2">Status</span></td>
                                        <td className="p-1"><span className="d-block fs-5 text-left text-dark fw-bold">{Data.Status ? "Enable" : "Disable"}</span></td>
                                    </tr>
                                    <tr className="text-center">
                                        <td className="p-1 pt-3" colspan="2">
                                            <button className="btn btn-outline-success fs-6 fw-bold text-center mr-2" onClick={() => setModalShow(true)}>Delete</button>
                                            <button className="btn btn-outline-success fs-6 fw-bold text-center" onClick={() => { setBUp(true); setItem(Data) }}>Update </button>
                                        </td>
                                    </tr>
                                </tbody>
                                : <p className="fs-6 fw-normal text-center py-2 m-0">No data found</p>
                            }
                        </table>
                    </div>

                </div>
                {
                    Item ?
                        <PartyUpdate
                            CompanyID={CompanyID}
                            BranchID={BranchID}
                            UserID={UserID}
                            item={Item}
                            show={BUp}
                            list={list}
                            setList={setList}
                            onReload={() => window.location.reload(false)()}
                            onHide={() => { setBUp(false); setItem(false) }}
                        /> : null
                }

                <DeleteMessage
                    FullName={`${Data ? Data.Title : null}`}
                    show={ModalShow}
                    Click={(e) => DeleteUser(e)}
                    onHide={() => setModalShow(false)}
                />

            </div>
        </div>
    );
}
const mapStateToProps = (state, props) => ({
    display: state.OverlayDisplay,
    user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Details);