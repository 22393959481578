import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../../../actions/auth';

const ResetPassword = ({ reset_password }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        reset_password(email);
        setRequestSent(true);
    };

    if (requestSent) {
        return <Redirect to='/wellcome' />
    }

    return (
        <section className="content">
            <div className="container-fluid">
                <div className='container mt-5'>
                    <h1>Request password Reset:</h1>
                    <form onSubmit={e => onSubmit(e)}>
                        <div className='form-group'>
                            <input
                                className='form-control'
                                type='email'
                                placeholder='email'
                                name='email'
                                value={email}
                                onChange={e => onChange(e)}
                                required
                            />
                        </div>
                        <button className='btn btn-primary' type='submit'>Reset password</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default connect(null, { reset_password })(ResetPassword);
